<template>
    <div class="d-flex justify-content-between align-items-center mb-3">
        <h3 class=" text-start text-white ">Chart</h3>
        <div class="d-flex align-items-center justify-content-end gap-3" style="display: flex!important;">
            <select name="time" id="time" v-model="timeRange">
                <option value="month">{{ $t('LastMonth') }}</option>
                <option value="week">{{ $t('LastWeek') }}</option>
                <option value="today">{{ $t('Today') }}</option>
            </select>
            <button style="border-radius: 10px;" class=" btn btn-primary ms-2" :class="{ 'btn-disabled': !chartTracking}" @click="captureScreenshot" :disabled="!chartTracking">Download</button>
        </div>
    </div>

   <div id="graficoMetriche" class="gap-3 mb-2" style="display: flex!important" v-if="metrics">
        <div>
            <p class="text-dark">{{ $t('Balance') }}</p>
            <p v-if="metrics?.balance"> {{ currency }} {{ (metrics?.balance || 0).toLocaleString('it-IT') }}</p>
        </div>
        <div>
            <p class="text-dark">{{ $t('HBalance') }}</p>
            <p v-if="metrics?.highestBalance"> {{ currency }} {{ (metrics?.highestBalance || 0).toLocaleString('it-IT') }}</p>
            <p v-else>-</p>
        </div>
        <div class="border border-1 d-none d-lg-block"></div>
        <div>
            <p class="text-dark">{{ $t('Equity') }}</p>
            <p>{{ (metrics?.equity || 0).toLocaleString('it-IT') }}</p>
        </div>
        <div>
            <p class="text-dark">{{ $t('Equity') }} %</p>
            <p>{{ (metrics?.equityPercent || 0).toFixed(2) }}</p>
        </div>
   </div>
    <div id="graficoMetriche" class="skeleton gap-3 mb-2" style="display: flex!important" v-else>
        <div>
            <p class="text-dark skeleton-exclude">{{ $t('Balance') }}</p>
            <p></p>
        </div>
        <div>
            <p class="text-dark skeleton-exclude">{{ $t('HBalance') }}</p>
            <p></p>
        </div>
        <div class="border border-1 d-none d-lg-block"></div>
        <div>
            <p class="text-dark skeleton-exclude">{{ $t('Equity') }}</p>
            <p></p>
        </div>
        <div>
            <p class="text-dark skeleton-exclude">{{ $t('Equity') }} %</p>
            <p></p>
        </div>
    </div>
    <div v-if="!error" class="h-100">
        <SpinnerChart v-show="loading" />

        <div v-show="!loading" class="h-100">
            <div  style="height:100%" class="card d-flex flex-column align-items-center py-5">
                <canvas ref="chartRef" id="myChart" height=100%!important width=100%!important></canvas>
            </div>
        </div>
        <div v-show="loading" class="h-100">
            <div class="card d-flex flex-column align-items-center py-5 justify-content-center">
                <i class="fa fa-spinner fa-spin fa-5xl"></i>
            </div>
        </div>
    </div>
    <div v-else>
        <p>{{ $t('Chart-notLoad') }} </p>
    </div>
</template>

<script setup>
import moment from 'moment';
import Chart from "chart.js/auto";
import html2canvas from 'html2canvas';
import SpinnerChart from './SpinnerChart';
import {onMounted, ref, shallowRef, watch} from "vue";

// Props
const props = defineProps({
    metrics: Object,
    currency: String,
    chartTracking: Object,
    accountId: String,
    account: Object
});

// Data
const timeRange = ref('week');
const error = ref(false);
const loading = ref(false);

// Chart
const chartRef = ref(null);
const chart = ref(null);
const createChart = () => {
    const chartInstance = new Chart(chartRef.value, {
        data: {
            labels: [],
            datasets: []
        },
        options: {
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: false,
                    }
                },
                y: {
                    title: {
                        color: 'white',
                        display: true,
                    },
                    ticks: {
                        color: 'white',
                        callback: function (val) {
                            return props.currency + (+val).toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            });
                        }
                    },
                    grid: {
                        display: true,
                        color: 'white'
                    }
                }
            },
            plugins: {
                legend: {
                    position: "bottom",
                    display: true,
                    labels: {
                        usePointStyle: true,
                        pointStyle: 'circle',
                        padding: 15,
                        color: "white",
                        font: {
                            size: 13
                        }
                    }
                },
                tooltip: {
                    padding: 10,
                    boxPadding: 3,
                    xAlign: 'center',
                    yAlign: 'center',
                    titleSpacing: 15,
                    bodySpacing: 10,
                    font: {
                        size: 30
                    }
                },
            },
        }
    });
    chart.value = shallowRef(chartInstance);
}

// Data
const getData = async () => {
    if ((props.accountId || '').length === 0) {
        error.value = true;
        return;
    }

    if (!props.chartTracking.label.length) {
        console.debug('No label for chart');
        return;
    }

    loading.value = true;
    error.value = false;

    let labels = [];
    switch (timeRange.value) {
        case 'week':
        labels = props.chartTracking.label.map((label, index) => {
           const labelBetween = moment(label).isBetween(
              moment().subtract(1, 'week').startOf('isoWeek'),
              moment()
           );
           if (labelBetween) {
               return index;
           }
           return false;
        });
        break;
      case 'month':
        labels = props.chartTracking.label.map((label, index) => {
          const labelBetween = moment(label).isBetween(
              moment().startOf('month').startOf('day'),
              moment()
          );
          if (labelBetween) {
              return index;
          }
          return false;
        });
        break;
      case 'today':
        labels = props.chartTracking.label.map((label, index) => {
          const labelBetween = moment(label).isBetween(
              moment().subtract(1, 'day').startOf('day'),
              moment()
          );
          if (labelBetween) {
              return index;
          }
          return false;
        });

        if (!labels.length) {
            let amountDay = 0;
            do {
                amountDay++;

                labels = props.chartTracking.label.map(label => {
                    const labelBetween = moment(label).isBetween(
                        moment().subtract(amountDay, 'day').startOf('day'),
                        moment().subtract(amountDay, 'day').endOf('day')
                    );
                    if (labelBetween) {
                        return label;
                    }
                    return false;
                });
            } while (!labels.length)
        }
        break;
      default:
        labels = Object.keys(props.chartTracking.label);
    }
    const labelIndexes = labels.filter(Boolean);
    console.debug('labelIndexes @ ChartLine', labelIndexes);

    const balanceData = props.chartTracking.balance.filter((balance, index) => labelIndexes.includes(index));
    const equityData = props.chartTracking.equity.filter((equity, index) => labelIndexes.includes(index));
    const margin5 = props.chartTracking._5limit.filter((limit, index) => labelIndexes.includes(index));
    const margin10 = props.chartTracking._10limit.filter((limit, index) => labelIndexes.includes(index));
    const avgMargin5 = (props.account.props?.max_daily_drawdown || 5) + "% Margin";
    const avgMargin10 = (props.account.props?.max_drawdown || 10) + "% Margin";
    console.debug('equityData @ ChartLine', equityData);

    chart.value.value.data.labels = props.chartTracking.label.filter((label, index) => labelIndexes.includes(index)).map(label => moment.utc(label).format('YYYY-MM-DD HH:mm'));
    chart.value.value.data.datasets = [
        {
            label: "Equity",
            data: equityData,
            borderColor: "#fb9678",
            fill: false,
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            type: "line",
            borderWidth: 3,
            lineTension: 0.4
        },
        {
            label: "Balance",
            data: balanceData,
            borderColor: "#00c292",
            fill: false,
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            type: "line",
            borderWidth: 3,
            lineTension: 0.4
        },
        {
            label: avgMargin5,
            data: margin5,
            borderColor: "#ff0019",
            fill: false,
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            type: "line",
            borderWidth: 3,
            borderDash: [5],
            lineTension: 0.4
        },
        {
            label: avgMargin10,
            data: margin10,
            borderColor: "#fff",
            fill: false,
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
            type: "line",
            borderWidth: 3,
            borderDash: [10],
            lineTension: 0.4
        }
    ];
    chart.value.value.update();

    loading.value = false;
}

// Methods
const captureScreenshot = async () => {
    const chart = document.getElementById('chartRef');
    const canvas = await html2canvas(chart, {backgroundColor: '#353c48'});

    const link = document.createElement('a');
    link.download = 'screenshot.png';
    link.href = canvas.toDataURL();
    link.click();
}


// Hooks
onMounted(() => {
    createChart();
    // getData();
});
watch(() => timeRange.value, () => {
    getData();
});
watch(() => props.chartTracking, () => {
    getData();
});
</script>

<style scoped>
select{
    background-color: #3c4452;
    color: white;
}

@media only screen and (max-width: 600px) {
    #graficoMetriche{
        justify-content: space-between;
    }
}
</style>
