<template>
    <Spinner v-if="loadingPage"/>
  <div :style="loadingPage?'opacity:20%':''">
    <div v-if="!isHorizontal && loading" class="text-center w-100 position-absolute top-50 translate-middle" >
        <i class="icons-Rotate-Gesture fs-1">
            <p>{{ $t('Horizontal') }}</p>
        </i>
        <a href="/home" class="btn btn-info">{{ $t('Back') }}</a>
    </div>
    <div class="m-2" v-if="isHorizontal && loading">
        <AreaChart/>
    </div>
  </div>
</template>

<script>
import AreaChart from "./AreaChart";
import Spinner from "./Spinner"
export default {
    components:{
        AreaChart,Spinner
    },
  data() {
    return {
      hasReloaded: false,
      loading:true,
      loadingPage:true
    };
  },
  computed: {
    isHorizontal() {
      return window.screen.orientation.angle === 90 || window.screen.orientation.angle === -90;
    }
  },
  mounted() {
    console.log(window.screen.orientation);
    window.addEventListener('orientationchange', this.handleOrientationChange);
    window.onload = () => {
            this.loadingPage = false;
        };
  },
  beforeUnmount() {
    window.removeEventListener('orientationchange', this.handleOrientationChange);
  },
  methods: {
    handleOrientationChange() {
        this.loading=false
      if (!this.hasReloaded) {
        console.log('reload');
        this.hasReloaded = true;
        window.location.reload();
        this.loading=true
      } else {
        console.log('already reloaded');
      }
    }
  },
};
</script>

<style>

</style>

