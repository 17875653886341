<template>
    <BrandSection>
        <template v-slot:content>
            <div class="card-section p-3">
                <template v-if="!error">
                    <img src="/assets/images/EmailVerified.svg" class="py-3" />
                    <h2 class="text-white">{{ $t('email-verified') }}</h2>
                    <p class="text-center fw-normal my-4 description">
                        {{ $t('email-verified-success') }}
                    </p>
                    <p class="text-center fw-normal">{{ $t('BackTo') }} <a class="text-primary text-decoration-underline" href="/">{{ $t('Login') }}</a></p>
                </template>
            </div>
        </template>
    </BrandSection>
</template>

<script>
import BrandSection from '../components/layout/BrandSection.vue';

export default {
    components: {
        BrandSection
    },
    data() {
        return {
        }
    },
    methods:{
        verify(){
            axios.get(`${this.$baseUrl}/customers/verify-email/${this.$route.params.token}`).then((response)=>{
                console.log(response)
                if(response.data.status !== 200){
                    this.$router.push({ path: '/',})
                }
            })
        },
    },
    mounted(){
        this.verify();
    }
}
</script>

<style scoped lang="scss">
    :global(.card-section) {
        background-color: rgba(255, 255, 255, 0.05);
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
    }

    .description {
        white-space: break-spaces;
    }
</style>
