(() => {
    let url = '';

    if (location.hostname === '127.0.0.1' || location.hostname === 'localhost' || location.hostname.endsWith('.test')) {
        url= 'http://127.0.0.1:3000';
    } else {
        url = 'https://api.ofpfunding.com';
        //url = 'https://ofp-api.ait-tech.it';

        if (location.hostname.includes('dev')) {
            url = 'https://dev.api.ofpfunding.com';
        }
    }

    window.$socket = io(url, {
        transports: ['websocket'],
        path: '/socket.io',
        autoconnect: true
    });
})();
