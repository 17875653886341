<template>
    <div class="me-0 me-lg-5">
        <h4 class="text-white text-start">{{ $t('chart-LSTrades') }}</h4>
        <div style="height:300px; display: flex!important;" class="d-flex flex-column justify-content-center align-items-center">
            <canvas ref="chartRef"></canvas>
        </div>
    </div>
</template>

<script setup>
import Chart from "chart.js/auto"
import {onMounted, ref, shallowRef, watch} from "vue";

// Props
const props = defineProps({
    selectedoption: {
    type: String,
        required: true
    },
    metrics: Object,
    currency: Object
});

// Charts
const chartRef = ref(null); // HTML ref
const chart = ref(null);

const updateChart = () => {
    let longTrade = props.metrics.currencySummary.reduce((acc, curr) => {
      return (props.selectedoption.toLowerCase() === 'all')
          ? acc + (curr.long?.trades || 0)
          : (curr.currency.toLowerCase() === props.selectedoption.toLowerCase())
              ? acc + curr.long.trades
              : acc;
    }, 0);
    let shortTrade = props.metrics.currencySummary.reduce((acc, curr) => {
      return (props.selectedoption.toLowerCase() === 'all')
          ? acc + (curr.short?.trades || 0)
          : (curr.currency.toLowerCase() === props.selectedoption.toLowerCase())
              ? acc + curr.short.trades
              : acc;
    }, 0);

    longTrade /= props.metrics.currencySummary.length;
    shortTrade /= props.metrics.currencySummary.length;

    chart.value.value.data.labels = ['Long', 'Short'];
    chart.value.value.data.datasets = [
        {
            label: 'Total Trades',
            data: [longTrade, shortTrade],
            backgroundColor: [
              'rgba(0,194,146,1)',
              'rgba(228,106,118,1)',
            ],
            borderColor: [
              'white'
            ],
            borderWidth: 1,
            hoverOffset: 4
        }
    ];
    chart.value.value.update();
};

// Hooks
onMounted(() => {
    let chartInstance = new Chart(chartRef.value, {
        type: 'doughnut',
        data: {
          labels: ["Trades"],
          datasets: [],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              labels: {
                color: "white",
                font: {
                  size: 13
                }
              }
            }
          },
        },
    });
    chart.value = shallowRef(chartInstance);

    updateChart();
});

watch(() => props.selectedoption, (newVal, oldVal) => {
    if (newVal === oldVal) {
      return;
    }

    updateChart();
});
</script>

<style>

</style>
