<template>
    <div id="main-wrapper">
        <Spinner v-if="loading"/>
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <header class="topbar" >
        <Navbar />
    </header>
    <!-- ============================================================== -->
    <!-- End Topbar header -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <aside class="left-sidebar">
        <!-- Sidebar scroll-->
        <div class="scroll-sidebar" style="background-color: rgba(255, 255, 255, 0);">
            <!-- Sidebar navigation-->
            <Sidebar />
            <!-- End Sidebar navigation -->
        </div>
        <!-- End Sidebar scroll-->
    </aside>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper" >
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <!-- End Bread crumb and right sidebar toggle -->
            <!-- ============================================================== -->
            <!--- Start Content --->
            <div class="row justify-content-between mt-5 pb-5">
                <h1 class="text-start mb-5 text-white fw-bold">Checkout</h1>
                <div class="col-12 col-xl-6 rounded mt-5 order-2 order-xl-0" id="billing_details">
                    <h2 class="text-center mb-5 text-white fw-bold">Billing Details</h2>
                    <div class="mt-5">
                        <form class="form-horizontal w-100 form-material flex-column flexdisplay"  id="loginform" >
                            <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                <div class="col-12 " id="billing">
                                    <label class="mb-1 text-white" for="email">Email</label>
                                    <input class="form-control" type="email" disabled  id="email" :value="client.email">
                                </div>
                            </div>
                            <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                <div class="col-12 " id="billing">
                                    <label class="mb-1 text-white " for="firstName">Name</label>
                                    <input class="form-control border border-1 border-white px-2 rounded text-dark" type="text"  id="firstName" v-model="firstName">
                                </div>
                            </div>
                            <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                <div class="col-12 " id="billing">
                                    <label class="mb-1 text-white" for="lastName"> Last Name </label>
                                    <input class="form-control border border-1 border-white px-2 rounded text-dark" type="text"  id="lastName" v-model="lastName">
                                </div>
                            </div>
                            <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                <div class="col-12 " id="billing">
                                    <label class="mb-1 text-white" for="address">Address</label>
                                    <input class="form-control border border-1 border-white px-2 rounded text-dark" type="text"  id="address" v-model="address">
                                </div>
                            </div>
                            <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                <div class="col-12 " id="billing">
                                    <label class="mb-1 text-white" for="city">City</label>
                                    <input class="form-control border border-1 border-white px-2 rounded text-dark" type="text"  id="city" v-model="city">
                                </div>
                            </div>
                            <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                <div class="col-12 " id="billing">
                                    <label class="mb-1 text-white" for="zip">ZIP code</label>
                                    <input class="form-control border border-1 border-white px-2 rounded text-dark" type="text"  id="zip" v-model="zip">
                                </div>
                            </div>
                            <div class="form-group d-flex flex-column flex-lg-row gap-5 flexdisplay">
                                <div class="col-12 " id="billing">
                                    <label class="mb-1 text-white" for="country">Country</label>
                                    <select  name="country" class="form-control text border border-1 border-white px-2 rounded text-dark" id="country" v-model="country">
                                        <option value=""></option>
                                        <option v-for="state in options" :key="state.label"  :selected="country==state.value"  :value="state.value">{{state.label}}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-12 col-xl-6 order-1 order-xl-0" id="order_recap">
                    <div  class="col-12 d-flex flex-column mb-5 flexdisplay" style="display: flex !important;">
                        <div class="col-12 border border-1 border-white-50 p-5 rounded">
                            <div class="col-12 d-flex justify-content-between">
                                <h2 class="text-white fw-semibold">Your Order:</h2>
                                <button class="btn btn-danger rounded" @click="clearCart()">Clear Cart</button>
                            </div>
                            <h5 class="text-danger fw-bold">{{ textErrCat }}</h5>
                            <div class="d-flex justify-content-between align-self-center gap-2 mt-5 flexdisplay" v-for="order in orders" :key="order" style="display: flex !important;">
                                <div class="d-flex align-items-center">
                                    <h6 class="text-primary fw-bold me-3">1X</h6>
                                    <div class="d-flex flex-column">
                                        <h5 class="text-white">
                                            {{order.item }}
                                        </h5>
                                        <div>{{ order.platform }}</div>
                                    </div>
                                </div>
                                <div class="d-flex flex-row align-items-center gap-2 flexdisplay" >
                                    <h6 v-if="!isNaN(order.price)" class="justify-self-end text-danger m-0 p-0 pe-3 text-decoration-line-through">
                                        {{formatCurrency("USD" , order.price)}}
                                    </h6>
                                    <h4 v-if="!isNaN(order.price)" class="text-decoration-none m-0 text-white">{{ formatCurrency("USD" , order.priceBundle) }}</h4>
                                    <h6 v-else class="justify-self-end text-white m-0 p-0 pe-3">-</h6>
                                    <!-- <div @click="removeElement(order.id)" :class="paymeth=='open'|| paymeth=='close'?'btn disabled':'btn btn-danger rounded'" class="btn btn-danger rounded">Remove</div> -->
                                </div>
                            </div>
                            <hr>
                            <form  class="d-flex flex-column col-12 flexdisplay" >
                                <!-- <div class="d-flex justify-content-between align-items-center mb-1" >
                                    <label class="m-0 p-0 mb-2  text-gray" for="Cupon">Coupon:</label>
                                    <div class="d-flex gap-2 text-end">
                                        <input class="inputCoupon mb-2 mb-md-0" :readonly="validCupon? true:false " :class="validCupon==true?'':validCupon==false?'border border-3 border-danger':validCupon==null?'':''" type="text" id="Coupon"  name="Coupon" v-model="coupon" required>
                                        <div :class="paymeth=='open'|| paymeth=='close'?'btn disabled':'btn btn-primary rounded'"  @click="addCupon()">Add Coupon</div>
                                    </div>
                                </div> -->
                                <!-- <div v-if="textRespCupon!=''" :class="!validCupon?'text-end fw-bold text-danger ':'text-end fw-bold text-success '">{{ textRespCupon }}</div> -->
                                <div class="d-flex justify-content-between align-items-center flexdisplay mt-3" >
                                    <p class="m-0 p-0 mb-2  text-white"> TOTAL : </p>
                                     <h5  class="m-0 p-0 fw-bold text-white">{{formatCurrency("USD" , total)}}</h5>
                                    <!-- <div v-else-if="!isNaN(order.price) && percCoupon!=''">
                                        <h5  class="m-0 p-0 fw-bold text-white text-decoration-line-through">{{formatCurrency("USD" , order.price)}} </h5>
                                        <h4 v-if="typeCoupon=='percentage' && (order.price-(order.price*percCoupon)/100)>0" class="m-0 p-0 fw-bold text-white">{{formatCurrency("USD" ,(order.price-(order.price*percCoupon)/100))}}</h4>
                                        <h4 v-if="typeCoupon=='percentage' && (order.price-(order.price*percCoupon)/100)<=0" class="m-0 p-0 fw-bold text-white">{{formatCurrency("USD" ,0)}}</h4>
                                        <h4 v-if="typeCoupon=='amount' && order.price-percCoupon>0" class="m-0 p-0 fw-bold text-white">{{formatCurrency("USD" ,(order.price-percCoupon))}}</h4>
                                        <h4 v-if="typeCoupon=='amount' && order.price-percCoupon<=0" class="m-0 p-0 fw-bold text-white">{{formatCurrency("USD" ,0)}}</h4>
                                    </div> -->
                                    <!-- <h5 v-else class="justify-self-end text-white m-0 p-0 pe-3">-</h5> -->
                                </div>
                                <!-- <div v-if="percCoupon!='' && typeCoupon=='percentage'" class="text-end text-white mb-3">{{ percCoupon }}% will be applied to the total</div> -->
                                <!-- <div v-if="percCoupon!='' && typeCoupon=='amount'" class="text-end text-white mb-3">- {{ formatCurrency('USD', percCoupon) }} will be applied to the total</div> -->
                                <div class="my-4" >
                                    <div class=" flexdisplay" >
                                        <input  type="checkbox" id="terms" name="terms" v-model="isChecked2" value="true" required :disabled="paymeth=='open' || paymeth=='close'?true:false">
                                        <label class="mx-2 fs-5" for="terms">I Agree to the <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">Terms and Conditions</a></label><br/>
                                    </div>
                                    <p class="text-danger" v-if="checkText!=''">{{ checkText }}</p>

                                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div  class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-1" id="exampleModalLabel">Terms and Conditions</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-header">
                                                <h3>OFP ACCOUNT AGREEMENT</h3>
                                            </div>
                                            <div class="modal-body">
                                                <div>
                                                    <p>This OFP Agreement (the “Agreement”) governs rights and obligations in connection with the use of services provided by OverviewFX Ltd, also known as OFP, offered through the www.ofpfunding.com website (the “Website”). Please read this Agreement carefully. You are under no obligation to use the Services if you do not agree or understand any portion of this Agreement, nor should you use the Services unless you understand and agree to these Terms.</p>
                                                    <p>This Agreement is entered into by you (“you”, “your”, or the “Customer”) and OverviewFX Ltd, with its registered office at 71-75 Shelton St, London WC2H 9JQ, United Kingdom, Company number 13699305 (“we”, “our”, or the “OFP”). </p>
                                                    <p>(OverviewFX Ltd, also known as OFP, and the Customer, hereinafter each referred to individually as a “Party” and collectively as the “Parties”) </p>
                                                    <p>This Agreement is concluded electronically as part of the registration for the Services on the Website. You accept the agreement by clicking, in the user interface of the website, on “I agree with the OFP Agreement”, by which this Agreement shall be concluded. During the registration, you are obligated to truthfully fill in all information about yourself and complete the KYC verification process within 10 days of the registration, which will allow us to verify your identity. The information must correspond to the information provided by you during the purchase of the OFP Account. Otherwise, OFP may refuse to enter into the Agreement with you. By filling in the information about yourself, you are confirming that all such information is true and accurate to the best of your knowledge at the time. </p>
                                                </div>
                                                <br/>
                                                <h6 class="fw-bold mt-4">1. PURPOSE OF THE AGREEMENT </h6>
                                                <p>1.1. The purpose of this Agreement is to set out the conditions (i) under which the Customer shall be provided by OFP with instruments for demo trading intended for advanced traders, including access to a trading account for demo trading (OFP Account); (ii) under which the Customer may perform such demo trades using OFP Trading instruments; and (iii) under which the Customer shall be paid financial reward(s). The purpose of this Agreement is also to ensure the Customer’s access to the instruments for demo trading provided under this Agreement through the user interface on the Website (the “Client Dashboard”), or by the provision of access to applications provided by OFP or third parties.
                                                </p>
                                                <br/>
                                                <h6 class="fw-bold mt-4">2. SUBJECT MATTER OF THE AGREEMENT </h6>
                                                <p>2.1. The subject matter of this Agreement is OFP’s obligation to provide the Customer with instruments for demo foreign currency trading in the FOREX market or with other instruments in other financial markets, access to trading accounts for demo trading (OFP Accounts), analytical instruments, training and educational materials, (if applicable) other accompanying services for advanced traders, to enable the Customer to access and use those services (the “Services”) and, subject to the terms and conditions set out in this Agreement, OFP’s obligation to pay the Customer a certain financial reward calculated according to the rules defined in this Agreement (the “Reward”). Within the Services provided, OFP shall, in particular, enable the Customer to access the electronic interface in which the Customer shall perform demo trades under this Agreement (the “Trading Platform”).
                                                    </p>
                                                <p>
                                                2.2. The Customer further undertakes, when using the Services, and in particular when performing demo trades, to act in accordance with this Agreement, any third parties’ terms and conditions, applicable legal regulations, and other rules which the Customer is obligated to adhere to when using the Services.

                                                </p>
                                                <p>2.3. OFP shall not provide Services to Customer that: (i) is of nationality or is residing in Restricted Jurisdictions; (ii) is established or incorporated or has a registered office in Restricted Jurisdictions; (iii) is subject to the relevant international sanctions; or (iv) has a criminal record related to financial crime or terrorism. Restricted Jurisdictions means countries determined as such by OFP and published here on the Website. The OFP reserves the right to refuse, restrict or terminate the provision of any Services to Customer as per Clause 2.3. and such Customer is prohibited from using the Services, which includes also the use of the Client Section and/or Trading Platform.
                                                </p>
                                                <p>
                                                2.4. The meaning of the definitions, expressions, and abbreviations used in this Agreement can be found in clause 16.
                                                </p>
                                                <br/>
                                                <h6 class="fw-bold mt-4">3. CUSTOMER REPRESENTATIONS </h6>
                                                <p>3.1. The Customer represents and acknowledges that:</p><br/>
                                                <div class="ms-3">
                                                    <p> 3.1.1. no performance provided by OFP to the Customer under this Agreement, including all Services, may be considered as investment services or activities within the meaning of the legal regulations governing, in particular, investment services and capital markets; in particular, OFP does not receive or execute in any case, whether directly or indirectly, any trading instructions, does not trade on its own account, does not provide or conduct investment or other advice, research, recommendations or analyses, and does not give or forward to the Customer any trading instructions, does not receive any assets from, or manage any assets for, the Customer, and does not provide any related foreign exchange services;
                                                    </p>
                                                    <p>3.1.2. no employees or other staff or representatives of OFP are authorised to provide services within the meaning of Clause 3.1.1;
                                                    </p>
                                                    <p>3.1.3. all trading that the Customer performs through the Services is simulated only and the execution of a demo trade within the Services provided shall in no way constitute an instruction to trade in real financial markets or with real currency;
                                                    </p>
                                                    <p>3.1.4. the funds provided to the Customer for demo trading are not real and that the Customer has no right to deal with them beyond the scope of their use within the Services (in particular that they cannot be used for actual trading), that the Customer is not entitled to withdraw or transfer them, and that OFP does not in any circumstances receive any funds or other assets from, or manage any funds or other assets for, the Customer within the Services;
                                                    </p>
                                                    <p>
                                                    3.1.5. performance with OFP is not indicative of trading on real financial markets, and that past performance and demo gains are neither a guarantee nor an indication of any further performance; and
                                                    </p>
                                                    <p>    3.1.6. if the Customer’s access to the Client Section is cancelled, irrespective of the reason for its cancellation, the Customer shall lose access to the OFP Account, and thus to the Services, without being entitled to any compensation.
                                                </p>
                                                </div><br/>
                                                <p>3.2. In case the Customer enters into this Agreement or uses the Services within its business (entrepreneurial) activities within the meaning of applicable laws, the Customer is obligated to notify OFP about it and provide OFP with the Customer’s business identifiers.
                                                </p>
                                                <p>3.3. In performing this Agreement, the Customer shall comply with all applicable legal regulations and obtain, and maintain the validity of, all permits, licenses, consents, approvals or other acts issued by the competent authorities, which may apply to the Customer’s activities under this Agreement, and fulfil all notification, tax, fee or other obligations that may arise in connection with the performance of this Agreement, and in particular with the Reward. The Customer is authorised to access and use the Services only in accordance with the applicable legal regulations and is prohibited from circumventing any technical measures that may limit the availability of the Services in certain countries. In no event shall OFP bear any responsibility for the failure of the Customer to fulfil any of its obligations.
                                                </p>
                                                <p>3.4. Customer hereby acknowledges that the demo capital has no monetary value and it’s only a part of the Services provided to the Customer and Customer has no actual right, title or ownership to the demo capital, and that the OFP has all rights, interest and full ownership to demo capital (as part of the Services). The OFP Account and any and all rights related to the Services that are granted by the OFP to the Customer by virtue of this Agreement are personal to the Customer and cannot be transferred by contract or otherwise, nor are eligible for inheritance.
                                                </p>
                                                <br/>
                                                <h6 class="fw-bold mt-4">4. OFP ACCOUNT </h6>
                                                <p>4.1. OFP shall provide the Customer with access to a trading account for advanced users, which will be linked to access to the Trading Platform (the “OFP Account”), as part of the Services. The OFP Account will always be activated on the day of the purchase.
                                                </p>
                                                    <p>4.2. Access to the OFP Account, and thus also to the Trading Platform, is protected by login data that OFP will display in the Client Section.
                                                </p>
                                                    <p>4.3. The Customer shall not make available or provide the login data to the OFP Account, the Trading Platform, or the Client Section to any third party. In case the Customer is a legal entity, the Customer may allow its employees or other authorised staff and representatives to access and use the Services. The Customer is responsible for the protection of login data and the OFP Account and for all activities that are performed through the OFP Account or the Trading Platform. The OFP shall not be liable, and the Customer is not entitled to any compensation, for any misuse of the OFP Account, Trading Platform, or any part of the Services and for any negative consequences thereof for the Customer, if such misuse occurs for any reason beyond the control of OFP.
                                                </p>
                                                    <p>4.4. The Customer acknowledges that the Trading Platform is provided by a third party and that the contractual terms and conditions and the privacy policy of its provider apply to the use of the Trading Platform. The Customer is obligated to read those terms and conditions and the privacy policy of the third party before the first access to the Trading Platform. By using the Trading Platform, the Customer agrees to those terms and conditions and the privacy policy.
                                                </p>
                                                    <p>4.5. All data provided by the Customer to OFP must be complete, true, and up-to-date. In the event of any change in the Customer’s data, the Customer must immediately send a notification to OFP from the Customer’s registered e-mail address; the notification must be accompanied by documents evidencing the change, if applicable. The Customer is responsible for all the provided data being accurate and up-to-date at all times; OFP is not obligated to verify the data.
                                                </p>
                                                    <p>4.6. The OFP Account and the Services may not be available at all times, in particular with respect to their maintenance, upgrades, technical errors, or for any other reason. In particular, OFP shall not be liable, and the Customer is not entitled to any compensation, for the unavailability of the OFP Account, the Client Section, or the Trading Platform, for any damages or loss of any data or other content that the Customer uploads, transfers, or saves via the OFP Account, the Client Section or the Trading Platform, or for any OFP’s interventions or alterations in the Customer’s OFP Account requested by the Customer. OFP may decide at its own discretion to compensate the Customer for the unavailability of the Services originating in technical reasons on the part of OFP. The Customer is not entitled to any compensation, and it is entirely at the discretion of OFP whether compensation is provided to the Customer and in what form or amount; provided however, the limitation of liability within the meaning of Clause 9 is not to be affected thereby.
                                                </p>
                                                    <p>4.7. In order to use the Services, the Customer must obtain the appropriate technical equipment and software, including third-party software (e.g., software for the use of the Trading Platform), at the Customer’s own risk and expense. OFP does not warrant or guarantee that the Services will be compatible with any specific equipment or software. OFP provides no warranty as to the compatibility of the Services with any particular device, program, or add-on.
                                                </p>
                                                <h6 class="fw-bold mt-4">5. DEMO TRADING </h6>
                                                <p>5.1. The Customer will perform demo trades through the Customer’s OFP Account on the selected Trading Platform. During the demo trading on the Trading Platform, the Customer may perform any trades, subject to the conditions in Clause 5.2, and unless those trades constitute forbidden trading strategies or practise within the meaning of Clause 5.3. You also agree to follow good market standard rules and practices for trading on financial markets (e.g., risk management rules). Restrictions may also be imposed by the terms and conditions of the Trading Platform.
                                                </p>
                                                <p>5.2 The Customer is obligated to comply with the following parameters in the course of the demo trading under this Agreement for the applicable trading options:
                                                </p>
                                                <div class="ms-3">
                                                    <p>5.2.1. during any Calendar day, the Customer’s account equity shall not decrease more than the account margin, present in the table below, from the maximum balance of the day. The account margin is 5% of the OFP Account's initial deposit balance. Each day is considered to be starting from midnight UTC +0. Breaking the rule will lead to the account closure.
                                                    </p>
                                                    <table class="table">
                                                        <thead>
                                                            <th>Account Initial Balance</th>
                                                            <th>5,000</th>
                                                            <th>10,000</th>
                                                            <th>25,000</th>
                                                            <th>50,000</th>
                                                            <th>100,000</th>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Margin</td>
                                                                <td>250</td>
                                                                <td>500</td>
                                                                <td>1,250</td>
                                                                <td>2,500</td>
                                                                <td>5,000</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <p>Taking into example an account with initial capital equal to $10,000, if the maximum account balance of the day is $ 12,300, the account equity cannot be lower or equal to $11,800.
                                                    </p>
                                                    <p> 5.2.2. the value of the simulated capital in the OFP Account which the Customer uses for the demo trading, including all positions opened in that account, may not at any time fall below the reference balance minus 105 of the initial capital. The reference balance will start from the initial deposit and will be updated to the account balance on Review day every time a Reward is earned.
                                                    </p>
                                                    <p>     5.2.3. no single trading day profit can account for 25% or more of the total Profit and Loss (PnL) accumulated from the last Reward earned or the purchase of the account. If an OFP Account has a percentage equal to or higher than 25%, the Reward will be postponed until the following review date and added to the next payout, if the account was not suspended in the meantime or did pass the rule in Clause 5.2.3.
                                                </p>
                                                </div>
                                                <p>5.3. FORBIDDEN TRADING PRACTICES.</p>
                                                <div class="ms-3">
                                                    <p> 5.3.1. DURING THE DEMO TRADING, IT IS PROHIBITED TO:
                                                    (1)KNOWINGLY OR UNKNOWINGLY USE TRADING STRATEGIES THAT EXPLOIT ERRORS IN THE SERVICES SUCH AS ERRORS IN THE DISPLAY OF PRICES OR DELAYS IN THEIR UPDATES;
                                                    <br>(2)PERFORM TRADES USING AN EXTERNAL OR SLOW DATA FEED;
                                                    <br>(3)PERFORM, ALONE OR IN CONCERT WITH ANY OTHER PERSONS, INCLUDING BETWEEN CONNECTED ACCOUNTS, OR ACCOUNTS HELD WITH DIFFERENT OFP ENTITIES, TRADES OR COMBINATIONS OF TRADES THE PURPOSE OF WHICH IS TO MANIPULATE TRADING, FOR EXAMPLE BY SIMULTANEOUSLY ENTERING INTO OPPOSITE POSITIONS;
                                                    <br>(4)PERFORM TRADES IN CONFLICT WITH THE TERMS AND CONDITIONS OF THE PROVIDER OF THE CLIENT SECTION AND THE TERMS AND CONDITIONS OF THE TRADING PLATFORM;
                                                    <br>(5)USE ANY SOFTWARE, ARTIFICIAL INTELLIGENCE, ULTRA-HIGH SPEED, OR MASS DATA ENTRY WHICH MIGHT MANIPULATE, ABUSE, OR GIVE YOU AN UNFAIR ADVANTAGE WHEN USING OUR SYSTEMS OR SERVICES;
                                                    <br>(6)PERFORM GAP TRADING BY OPENING TRADE(S): WHEN MAJOR GLOBAL NEWS, MACROECONOMIC EVENT OR CORPORATE REPORTS OR EARNINGS (“EVENTS”), THAT MIGHT AFFECT THE RELEVANT FINANCIAL MARKET (I.E. MARKET THAT ALLOWS TRADING OF FINANCIAL INSTRUMENTS THAT MIGHT BE AFFECTED BY THE EVENTS), ARE SCHEDULED; AND (II)2 HOURS OR LESS BEFORE A RELEVANT FINANCIAL MARKET IS CLOSED FOR 2 HOURS OR LONGER; OR (g) OTHERWISE PERFORM TRADES IN CONTRADICTION WITH HOW TRADING IS ACTUALLY PERFORMED IN THE FOREX MARKET OR IN ANY OTHER FINANCIAL MARKET, OR IN A WAY THAT ESTABLISHES JUSTIFIED CONCERNS THAT OFP MIGHT SUFFER FINANCIAL OR OTHER HARM AS A RESULT OF THE CUSTOMER’S ACTIVITIES (E.G. OVERLEVERAGING, OVEREXPOSURE, ONE-SIDED BETS, ACCOUNT ROLLING).
                                                    </p>
                                                    <p>
                                                    5.3.2. As our Customer, you should understand and you agree that all our Services are for Customer’s personal use only, meaning that only you personally can access your OFP Account and perform trades. For that reason, you should not, and you agree not to,
                                                    (a) allow access to and trading on your OFP Account by any third party nor you shall engage or cooperate with any third party in order to have such third party perform trades for you, whether such third party is a private person or a professional;
                                                    (b) access any third party OFP Account, trade on behalf of any third party or perform any account management or similar services, where you agree to trade, operate or manage the OFP Account on behalf of another user, all whether performed as a professional or otherwise.
                                                    Please note that if you act or behave in contradiction with the aforesaid, we will consider such action/behaviour as a Forbidden Trading Practice under Section 5.3 with respective consequences as perceived under this Agreement.

                                                    </p>
                                                <p>
                                                    5.3.3. Furthermore, you shall not exploit the Services by performing trades without applying market standard risk management rules for trading on financial markets, this includes, among others, the following practices (i) opening substantially larger position sizes compared to your other trades, whether on this or any other account of yours, or (ii) opening substantially smaller or larger number of positions compared to your other trades, whether on this or any other account of yours.
                                                    The OFP reserves the right to determine, at its own discretion, whether certain trades, practices, strategies or situations are Forbidden Trading Practices.

                                                </p></div>
                                                <p>5.4. The value of the initial demo capital for demo trading under this Agreement (the “Capital”), and, where applicable, the currency, risk option, and other parameters that apply to the demo trading under this Agreement, are set out in Schedule A.</p>
                                                <p>5.5. If the Customer becomes entitled to a Reward, the demo trading within the given trading cycle shall be terminated, and all open positions shall be closed. The Customer is obliged to close all trading positions by 11.59 PM of the day preceding the Review Day; if the Customer fails to close positions in time, OFP will automatically close all open positions on behalf of the Customer. After verification of the legitimacy of the Customer’s entitlement to the Reward, the Customer shall be allowed to start a new trading cycle.
                                            </p><p>5.6. If the Customer is not entitled to a Reward on Review Day, the Customer will continue with demo trading under the same terms, i.e.
                                            </p><p>5.7.1. the Review of the demo trading account is performed every 28 calendar days from the purchase of the account;
                                            </p><p>5.7.2. all parameters of the OFP Account, including the current amount of the Capital with which the Customer performs demo trades, will remain unaffected;
                                            </p><p>5.7. If the Customer engages in any of the Forbidden Trading Practices described in Clause 5.3, the Customer will lose the right to a Reward and, in addition, (i) OFP may remove those demo trades that violate the prohibition from the Customer’s trading history and/or (ii) not count their results in the profits and/or losses achieved by the demo trading, (iii) and/or to immediately cancel the Customer’s OFP Account and subsequently terminate this Agreement, and/or (iv) reduce the offered leverage on any or all Customer’s accounts. In case when some or all Forbidden Trading Practices are executed on one or more OFP Accounts of one Customer, or OFP Accounts of various Customers, or by combining trading on OFP Account, then OFP is entitled to cancel all OFP Accounts and terminate respective agreements, used for or involved in the Forbidden Trading Practices and/or apply other measures in this Clause 5.8. OFP may exercise any and all actions in this Clause 5.8 at its own discretion.
                                            </p><p>5.8. OFP bears no responsibility for any information displayed on the Trading Platform or for any interruptions, delays or inaccuracies in the market or statistical data displayed through the Trading Platform or the instruments accessible within the Services provided.
                                            </p><p>5.9. The Customer shall not perform the same trading strategy, i.e., perform the same trades at the same time, on multiple OFP Accounts while performing demo trading with us, unless the total sum of Capital on all such OFP Accounts is less than 100,000. In case different types of accounts are combined then specific rules apply: (i) for any combination of OFP Accounts the maximum total amount of the Capital shall be 100,000 per Customer. In case the OFP has a reasonable concern that the Customer has not complied with Clause 5.9, the OFP may, at its own discretion and without prior notice (i) permanently decrease the maximum amount of the Capital on each affected OFP Account proportionally, in a manner that the total demo capital on all affected OFP Accounts shall together amount to a maximum amount of the Capital as provided in Clause 5.5 and/or (ii) cancel the concerned OFP Accounts and/or (iii) immediately terminate this Agreement.
                                            </p><br/>
                                                <h6 class="fw-bold mt-4">6. REWARD</h6>
                                                <p>6.1. The Customer’s entitlement to the Reward and its amount will depend exclusively on the results of the Customer’s demo trading and fulfilment of the parameters set by OFP for its payment. The Customer acknowledges that the entitlement or the amount of the Reward does not in any way depend on developments in the real financial markets or on the value that the demo trades would have in the real financial markets.
                                                </p><p>6.2. The Customer will only become entitled to the Reward if the Customer meets all the conditions for demo trading set out in this Agreement, in particular if the Customer meets all the parameters set out in Clause 5.2; at review day, the difference between the highest balance obtained in any previous review day (or the initial deposit, whichever is higher) and the value of the balance at the current review day (the “Profit”); and if, the invoice is duly issued. If the Customer does not achieve a Profit by the beginning of the review day, the Customer will not be entitled to any Reward for the given period. For the purposes of determining the Customer’s entitlement to the Reward, only the Customer’s results achieved within the Customer’s demo trades in the OFP Account will be taken into account.
                                                </p><p> 6.3. The amount of the Reward depends on the chosen account model. The amount of the Reward determined as stated above is final and includes all taxes, whether direct or indirect, including VAT (Value Added Tax), GST (Goods and Services Tax), or similar taxes, levies and fees, which may apply to it under the relevant legal regulations. The Customer is solely responsible and liable for the payment of any and all taxes, whether direct or indirect, including VAT (Value Added Tax), GST (Goods and Services Tax), or similar taxes, levies or fees that apply to the Customer in relation to this Agreement under the applicable laws and acknowledges and agrees that OFP shall not be liable for any such obligations of the Customer. The currency of the Reward and the payment of the Reward to the Customer will be the same as the currency of the Capital of the Customer’s OFP Account.
                                                </p><p>6.4. If the Customer generates a Profit on review day, OFP will verify the legitimacy of the Customer’s entitlement to the Reward, calculate it and communicate the result to the Customer via Client Section or email. Based on this communication, the Customer shall state which payment method he prefers and the relevant details.
                                                </p><p>6.5. The Reward will be paid to the Customer on the basis of a tax document – invoice. The data on the invoice shall always correspond to the information provided by the Customer in the form specified in Clause 7.5; otherwise, OFP is not obligated to pay the Reward. The Customer is always responsible for the correctness of the invoice and its compliance with the relevant legal regulations, irrespective of the method of its issuance. The invoice will be issued by OFP on behalf of the Customer (self-billing mechanism) on the basis of authorisation by the Customer or directly by the Customer, if the authorisation is revoked, as further detailed in Clause 7.7.1 and 7.7.2.
                                                </p><div class="ms-3">
                                                    <p>
                                                    6.5.1. Self-billing. The Customer hereby authorises OFP to repeatedly issue electronic invoices for the payment of the Reward on behalf of the Customer until such authorisation is revoked. The Customer may be requested to confirm this authorisation by email. The Customer shall comply with the following:
                                                    (a) The Customer must communicate to OFP, no later than upon the first submission of the form specified in Clause 7.5, all data necessary for the issuance of an invoice in the form that will meet all the requisites set out by the relevant legal regulations, and in the vent of any change in these data or requisites, promptly notify OFP thereof; the notification must be accompanied by documents evidencing the change, if applicable;
                                                    (b) If the Customer fails to fulfil the obligations as per the previous paragraph, the Customer may not use the procedure for issuing invoices under Clause 7.7.1; if the Customer fulfils the obligation under the previous sentence, the invoice will always be issued by OFP on behalf of the Customer, unless the Customer revokes the authorisation by e-mail. Any invoice issued directly by the Customer and sent to OFP while the authorisation to issue invoices on behalf of the Customer by OFP is effective will be disregarded by OFP.
                                                    (c) Invoices will be issued automatically no later than 7 Calendar days from the date on which OFP receives the form under Clause 7.5 from the Customer. The invoices will be due in 14 days.
                                                    (d) If an invoice does not contain the requisites set out by the relevant legal regulations, the Customer may notify OFP thereof within 10 Calendar days from the date of receipt of the invoice, stating the specific deficiencies and quantification of the applicable taxes and fees, and request a corrected invoice. After that period, OFP is not obligated to correct invoices. OFP will send a corrected invoice to the Customer within 10 Calendar days from the date of receipt of a relevant timely notification from the Customer. In such a case, the maturity period of the invoice will be interrupted, and a new maturity period will begin upon the issuance of a corrected invoice. The Customer is responsible for the content of the invoice so issued and must check the accuracy of the data immediately upon receipt of the issued invoice.
                                                    </p>
                                                    <p>6.5.2. In case the Customer revokes the authorisation for OFP to issue invoices under Clause 7.7.1 by e-mail, the Customer shall issue the invoice itself. In such case, the Customer shall send it to OFP to the following e-mail address: support@ofpfunding.com. The maturity period of any invoice issued by the Customer must be at least 14 days from its delivery to OFP. If an invoice issued by the Customer does not contain the requisites of a tax document under the relevant legal regulations, OFP may return it to the Customer to correct the errors and complete the data within 10 Calendar days from the date on which OFP received the invoice. In such a case, the maturity period of the invoice will be interrupted, and a new maturity period shall begin upon the delivery of the corrected faultless invoice to OFP.
                                                    </p>
                                                </div>
                                                <p>6.6 OFP will pay the Reward(s) in the manner specified by the Customer in the form under Clause 7.5 by wireless transfer to the Customer’s account, which the Customer shall state in the form under Clause 7.5, or by any other payment method accepted by OFP. All fees and costs of effecting the payment charged to the Customer (e.g., charged by a payment provider) are borne by the Customer.</p>
                                                <br/>
                                                <h6 class="fw-bold mt-4">7. USE OF THE SERVICES AND OTHER CONTENT</h6>
                                                <br/>
                                                <p>
                                                    7.1. All Services, including the OFP Account, their appearance and all applications, data, information, multimedia elements such as texts, drawings, graphics, design, icons, images, audio and video samples and other content that may form the Services (including the OFP Account), including any such content located on social networks of OFP or persons/entities that are members of the same group as OFP (the “Content”), are subject to legal protection under the legal regulations concerning copyright protection and other legal regulations and are the property of OFP or OFP’s licensors. OFP grants the Customer a limited, non-exclusive, non-transferable, non-assignable, non-passable, and revocable permission to use the Content for the purpose of using the Services for the Customer’s personal use and in accordance with the purpose for which the Services are provided. The Content is not sold or otherwise transferred to the Customer and remains the property of OFP or its licensors.

                                                </p>
                                                <p>7.2. All trademarks, logos, trade names, and other designations are the property of OFP or OFP’s licensors and OFP does not grant the Customer any right to use them unless explicitly permitted in a written form.
                                                </p><p>7.3. Both the Customer and OFP undertake to act in accordance with the principles of fair dealing in the performance of the Agreement and in mutual interactions and, in particular, not to damage the good reputation and legitimate interests of the other party. The Customer and OFP shall resolve any possible disagreements or disputes between them in accordance with this Agreement and the applicable legal regulations.
                                                </p><p>7.4. OFP, its affiliates, or licensors, own and retain all rights, title and interest to the Services, Trading Platform or any software provided hereunder or any part thereof, including all intellectual property rights thereto. Except for the rights expressly set out in this Agreement, OFP does not grant the Customer any other rights relating to the Services and other Content. The Customer may only use the Services and other Content as set out in this Agreement.
                                                </p><p>7.5. When accessing the Services and other Content, it is prohibited to:</p>
                                                <div class="ms-3">
                                                    <p>7.5.1. use any instruments that may adversely affect the operation of the Website and Services or that would be intended to misuse any errors, bugs, or other deficiencies of the Website and Services;
                                                    </p><p>7.5.2. circumvent geographical restrictions of availability or any other technical restrictions;</p>
                                                    <p>7.5.3. make copies or back-ups of the Website and other Content;</p>
                                                    <p> 7.5.4. reverse-engineer, decompile, disassemble, or otherwise modify the Website and other Content;</p>
                                                    <p>7.5.5. sell, rent, lend, license, distribute, reproduce, spread, stream, broadcast, or use the Services or other Content otherwise than as permitted;
                                                    </p><p>7.5.6. use automated means to view, display, or collect information available through the Website or Services; and
                                                    </p> <p>7.5.7. use any other instruments or means the use of which could cause any harm to OFP.</p>

                                                </div><br/>
                                                <p>7.6. If the Customer finds out that the Services or any other Content contains errors, bugs, or other deficiencies, the Customer shall notify OFP thereof without undue delay in writing.
                                                </p>
                                                <p>7.7. If the Customer violates any restriction or prohibition under Clause 8, the Customer is obligated to indemnify OFP or companies that are members of the same group as OFP, or their customers, employees, members of governing bodies, shareholders, business partners, licensors of these persons or any cooperating persons (the “Indemnified Persons”), for, and hold them harmless from, any claims of third parties against the Indemnified Persons to the extent of any claims made or lawsuits filed by a third party on the basis of which the third party alleges that the rights of that third party have been violated or that the applicable law is violated in connection with the Customer’s conduct, and the Customer shall pay all damages, including lost profits, costs of legal proceedings, and legal representation and other financial or non-financial claims arising from the claims or lawsuits of that third party.<br/>
                                                </p>
                                                <br>
                                                <h6 class="fw-bold mt-4">8. LIMITATION OF LIABILITY</h6>
                                                <p>8.1. THE CUSTOMER ACKNOWLEDGES THAT THE SERVICES AND OTHER CONTENT ARE PROVIDED “AS IS” WITH ALL THEIR ERRORS, DEFECTS, AND SHORTCOMINGS, AND THAT THEIR USE IS AT THE CUSTOMER’S SOLE RESPONSIBILITY AND RISK. TO THE MAXIMUM EXTENT PERMITTED BY THE MANDATORY LEGAL REGULATIONS, OFP DISCLAIMS ANY STATUTORY, CONTRACTUAL, EXPRESS AND IMPLIED WARRANTIES OF ANY KIND, INCLUDING ANY WARRANTY OF QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF ANY RIGHTS.  </p>
                                                <p>
                                                8.2. TO THE EXTENT PERMITTED BY THE MANDATORY PROVISIONS OF THE APPLICABLE LEGAL REGULATIONS, OFP IS NOT RESPONSIBLE FOR ANY HARM, INCLUDING ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGE, INCLUDING LOST PROFIT, LOSS OF DATA, PERSONAL OR OTHER NON-MONETARY HARM OR PROPERTY DAMAGE CAUSED AS A RESULT OF USE OF THE SERVICES OR RELIANCE ON ANY INSTRUMENT, FUNCTIONALITY, INFORMATION, OR ANY OTHER CONTENT AVAILABLE IN CONNECTION WITH THE USE OF THE SERVICES OR ELSEWHERE ON THE WEBSITE. OFP IS NOT RESPONSIBLE FOR ANY PRODUCTS, SERVICES, APPLICATIONS OR OTHER CONTENT OF THIRD PARTIES THAT THE CUSTOMER USES IN CONNECTION WITH THE SERVICES. IN CASE THE OFP’S RESPONSIBILITY IS INFERRED BY A COURT OF JUSTICE OR ANY OTHER COMPETENT AUTHORITY IN CONNECTION WITH THE OPERATION OF THE WEBSITE OR PROVISION OF THE SERVICES, THIS RESPONSIBILITY SHALL BE LIMITED TO A MAXIMUM AMOUNT OF USD 10,000.
                                                </p>
                                                <p>8.3. OFP reserves the right to modify, change, replace, add, or remove any elements and functions of the Services at any time without any compensation. </p>
                                                <p>
                                                8.4. OFP is not responsible for its failure to provide the Services if that failure occurs due to serious technical or operational reasons beyond OFP’s control, in the case of any crisis or imminent crisis, natural disaster, war, insurrection, pandemic, a threat to a large number of people, or any other force majeure event, and/or if OFP is prevented from providing the Services as a result of any obligations imposed by a legal regulation or a decision of a public authority. </p>
                                                <p>8.5. Should any information or statement of any employee or other staff or representatives of OFP be interpreted as investment advice or recommendation, OFP is not responsible for such information or statement.</p>
                                                <p>8.6. OFP bears no responsibility for trading or other investment activities performed by the Customer outside the relationship with OFP, for example by using data or other information from the OFP Account, Trading Platform or otherwise related to the Services in real trading in financial markets, not even if the Customer uses for such trading the same Trading Platform that the Customer has selected for demo trading.</p>
                                                <br/>
                                                <h6 class="fw-bold mt-4">9. CONFIDENTIALITY </h6>
                                                <br/> 9.1. The Customer is obligated to maintain confidentiality regarding the terms and conditions of this Agreement, the existence of this Agreement, the manner in which the Services are provided, the content of communication with OFP, all information that may constitute trade secrets, including know-how, and any information made available to the Customer by OFP which is designated as confidential or which is to be considered as confidential by reason of the nature of the information and the circumstances in which it was disclosed (the “Confidential Information”).
                                                <br/>9.2. The obligation to protect the Confidential Information under Clause 10 shall not apply to cases where (i) the information is publicly available or known at the time of its use or disclosure unless its public availability or knowledge was due to a breach of a legal or contractual obligation; or (ii) the obligation to disclose the Confidential Information is required by law or any other legal regulation or based on a final decision of a court, arbitration body or administrative body.
                                                <br/>9.3. The Customer undertakes to comply with the obligations under this Clause 10 without any time limit also after the termination of the Agreement.
                                                <br/>
                                                <h6 class="fw-bold mt-4">10. COMMUNICATION </h6>
                                                <p>10.1. All communication made by OFP or its partners in connection with the performance of this Agreement will take place via the registered e-mail address of the Customer, which the Customer must notify OFP.
                                                </p>
                                                <p>10.2. All communication from the Customer in connection with the performance of this Agreement must take place via the following contact e-mail address of OFP: <span class="text-info">support@ofpfunding.com</span>.
                                                </p>
                                                <p>10.3. Written communication also includes written electronic communication by e-mail.
                                                </p>
                                                <br/>

                                                <h6 class="fw-bold mt-4">11. DURATION AND AMENDMENTS OF THE AGREEMENT</h6>
                                                <p>
                                                    11.1. This Agreement is concluded for an indefinite period of time. This Agreement, however, terminates automatically, when the Customer fails to comply with one or more parameters as provided in Clause 5.2 or one of the Parties terminates this Agreement in accordance with this Clause 12.

                                                </p>
                                                <p>11.2. Either Party may terminate this Agreement without specifying any reason by serving a written notice in accordance with Clause 11 on the other Party, with effect from the date of delivery of the notice. In such a case, the OFP Account will be cancelled immediately, and all trades will be closed.
                                                </p><p>11.3. Upon termination of the Agreement, the Customer may become entitled to a Reward for the current trading period or part thereof. In such a case, the Trading Period will end on the day of cancellation of the OFP Account, on which all trades shall also be closed. Clause 7 of this Agreement shall respectively apply to the calculation of the Reward. In the events listed in Clause 12.4 and 12.5, the provisions of Clause 12.3 shall not apply and the Customer will not be entitled to the Reward.
                                                </p>
                                                <p>11.4. The Customer is not entitled to the Reward or a proportional part thereof if the Agreement is terminated hereunder due to a failure to comply with Clause 5.2.1.
                                                </p>
                                                <p>11.5. Furthermore, the Customer is not entitled to the Reward or a proportional part thereof in cases where OFP terminates this Agreement for any of the following reasons:
                                                </p>
                                                <div class="ms-3">
                                                    <p>  11.5.1. any representation of the Customer given in Clause 3.1 proves to be untrue;</p>
                                                    <p> 11.5.2. the Customer grossly violates any provision of this Agreement.
                                                    In particular, a repeated breach of any provision of the Agreement (i.e. more than three times) shall be considered a gross breach of the Agreement. The following (even if only one-off) violations shall also be considered gross violations: if the Customer accesses the Services in violation of Clause 3.3, if the Customer provides incomplete, untrue or outdated information in violation
                                                    of Clause 4.5, if the Customer engages in any of the Forbidden Trading Practices described in Clause 5.3, if the Customer engages in any of the activities listed in Clause 8.5, if the Customer acts in violation of Clause 8.3, and/or if the Customer violates the confidentiality obligation under Clause 10.
                                                    </p>
                                                </div>
                                                <br>
                                                <p>11.6. Given that the provision of the Services requires active access of the Customer to the Client Section, this Agreement shall also be terminated at the moment when the Customer loses access to the Client Section, irrespective of the reason for such loss. In such a case, Clause 12.3 shall apply regarding the entitlement to the Reward.</p>
                                                <p>11.7. Upon termination of this Agreement, the Customer shall return to OFP all documents, instruments, electronic, or other storage media, or any other means lent to the Customer by OFP and containing information about the activities of OFP. The Customer may not make any copies of the means mentioned in the previous sentence.</p>
                                                <p>11.8. If, as a result of a change in legislation or the practice of the relevant supervisory authorities, or as a result of a change in legal interpretation, a need arises to regulate the legal relationship established by this Agreement differently from a material or formal point of view, the Customer and OFP undertake to conclude an amendment to this Agreement or to replace this Agreement with a new agreement to meet any such need(s). If no such agreement is reached or the situation according to the previous sentence does not allow it or a decision of the relevant supervisory authority orders the termination of this Agreement, OFP may withdraw from this Agreement with immediate effect. In such a case, the Customer shall have no claims against OFP due to the termination of the Agreement.</p>
                                                <br>

                                                <h6 class="fw-bold mt-4">12. CHOICE OF LAW AND JURISDICTION </h6><br>
                                                <p>12.1. Any legal relations established by this Agreement or related to it, as well as any related non-contractual legal relations, shall be governed by the laws of the United Kingdom without regard for its conflict of laws provisions. Any dispute that may arise in connection with this Agreement and/or any related agreements shall fall within the jurisdiction of the United Kingdom court having local jurisdiction according to the registered office of OFP.
                                                </p><br/>

                                                <h6 class="fw-bold mt-4">13. CONSUMER INFORMATION </h6><br>
                                                <p>
                                                13.1. If the Services are not provided or do not correspond to what has been agreed, the Customer may file a complaint by sending an email to support@ofpfunding.com. OFP will resolve the complaint as soon as possible, no later than within 30 Calendar days, and will confirm its receipt and settlement to the Customer in writing.

                                                </p><br/>

                                                <h6 class="fw-bold mt-4">14. FINAL PROVISIONS </h6><br>
                                                <p>14.1. OFP undertakes to process personal data in accordance with the Privacy Policy. If the Customer is a legal entity, the Customer is obligated to acquaint with those documents the individuals to whom the Customer allows the use of the OFP Account and/or the Trading Platform under the conditions set out in this Agreement.
                                                </p>
                                                <p>  14.2. Nothing in this Agreement is intended to limit any legal claims set out elsewhere in this Agreement or arising from the applicable legal regulations. If OFP or any third party authorised thereto does not enforce compliance with this Agreement, this shall in no way be construed as a waiver of any right or claim.
                                                </p>
                                                <p>14.3. OFP may assign any claim arising to it under this Agreement or any agreement to a third party without the Customer’s consent. The Customer agrees that OFP may, as the assignor, transfer its rights and obligations under this Agreement or any agreement (or any parts thereof) to a third party. The Customer may not transfer or assign the Customer’s rights and obligations under this Agreement or any other agreement (or any parts thereof) or any receivables arising therefrom, in whole or in part, to any third party.
                                                </p>
                                                <p>14.4. If any provision of the Agreement is found to be invalid or ineffective, it shall be replaced by a provision whose meaning is as close as possible to the invalid provision. The invalidity or ineffectiveness of one provision shall not affect the validity of the other provisions. No past or future practice established between the Parties and no custom maintained in general or in the industry relating to the subject-matter of the performance which is not expressly referred to in the Agreement shall be applied and no rights and obligations shall be derived from them for the Parties, and, in addition, they shall not be taken into account in the interpretation of manifestations of the will of the Parties.
                                                </p>
                                                <p> 14.5. This Agreement constitutes the entire agreement on the subject matter of this Agreement concluded between OFP and the Customer and supersedes all prior agreements relating to the subject matter of the Agreement, whether verbal or written.
                                                </p>
                                                <p></p>
                                                <p>14.6. Prior to the mutual acceptance of this Agreement, the Parties have carefully assessed the possible risks arising from it and accept those risks. The Customer assumes the risk of a change of circumstances.
                                                </p>
                                                <p>14.7. References to clauses and schedules used in this Agreement are references to clauses and schedules of this Agreement. The schedules to the Agreement form integral parts of the Agreement. In the event of any conflict between the wording of the body of the Agreement and any of its schedules, the body of the Agreement shall prevail.
                                                </p>
                                                <br/>

                                                <h6 class="fw-bold mt-4">15. DEFINITIONS, EXPRESSIONS AND ABBREVIATIONS USED</h6><br>
                                                15.1. For the purposes of the Agreement, the following definitions shall have the following meanings: <br>
                                                <div class="ms-3">
                                                <p>15.1.1. "Agreement“ means this OFP Account Agreement (as set out in the introductory provisions);
                                                </p>
                                                <p>15.1.2. “Calendar day” means every full 24-hour calendar day, including weekends and public holidays, of the current time in the United Kingdom UTC+0.;
                                                </p><p>15.1.3. "Capital“ means the value of the initial demo capital for demo trading under the Agreement;
                                                </p><p>15.1.4. "Client Section“ means the user interface on the Website (as set out in Clause 1.1)
                                                </p><p>15.1.5. "Confidential Information“ means the terms and conditions of this Agreement, this Agreement, information on the manner in which the Services are provided, the content of communication with OFP, all information that may constitute trade secrets, including know-how, and any information made available to the Customer by OFP which is designated as confidential or which is to be considered as confidential by reason of the nature of the information and the circumstances in which it was disclosed (as set out in Clause 10.1);
                                                </p><p>15.1.6. "Content“ means all Services, including the OFP Account, their appearance and all applications, data, information, multimedia elements such as texts, drawings, graphics, design, icons, images, audio and video samples and other content that may form the Services (including the OFP Account), including any such content located on social networks of OFP or persons/entities that are members of the same group as OFP (as set out in Clause 8.1);
                                                </p><p>15.1.7. "Customer“ or "you“, "yours“ means a natural person or an entity that enters into the Agreement with OFP (as set out in the introductory provisions);
                                                </p><p>15.1.8. “Events” means events as set out in clause 5.3.1(f)(I);
                                                </p><p>15.1.10. "OFP Account“ trading account for advanced users, which will be linked to access to the Trading Platform (as set out in Clause 4.1);
                                                </p><p>15.1.11. "OFP“ or “we”, “our” means company OverviewFX Ltd, with its registered office at 71-75 Shelton St, London WC2H 9JQ, United Kingdom, company no.: 13699305 (as set out in the introductory provisions);
                                                </p><p>15.1.12. “Forbidden Trading Practices” means trading practices and strategies use of which is strictly prohibited, while using our Services, and as more detailed in Section 5.3;
                                                </p><p>15.1.13. "Indemnified Persons“ means companies that are members of the same group as OFP, or their customers, employees, members of governing bodies, shareholders, business partners, licensors of these persons or any cooperating persons (as set out in Clause 8.7);
                                                </p><p>15.1.14. "Party“ means OFP or the Customer individually and "Parties“ means OFP and the Customer collectively (as set out in the introductory provisions);
                                                </p><p>15.1.15. “Reference Balance” means the maximum balance present in the Customer’s OFP Account when a Reward was issued;
                                                </p><p>15.1.16. "Profit“ means the positive difference between the value of the balance in the Customer’s OFP Account (in which no positions are opened) and the value of the Reference balance;
                                                </p><p>15.1.17. “Review Day” means the last day of trading (and of the Trading Period);
                                                </p><p>15.1.18. "Trading Period“ means a period during which the results of the Customer’s demo trading will be evaluated by OFP (as set out in Clause 5.4);
                                                </p><p> 15.1.19. "Reward“ means a certain financial reward calculated according to the rules defined in this Agreement (as set out in Clause 2.1);
                                                </p><p>15.1.21. “Scaling Plan” means an exclusive trading option available to traders upon fulfilment of terms and conditions for Scaling Plan;
                                                </p><p>15.1.22. "Services“ means the provision of instruments for demo foreign currency trading in the FOREX market or with other instruments in other financial markets, access to trading accounts for demo trading (OFP Accounts), analytical instruments, training and educational materials, (if applicable) other accompanying services for advanced traders (as set out in Clause 2.1);
                                                </p><p>15.1.23. "Trading Platform“ means the electronic interface in which the Customer shall perform demo trades (as set out in Clause 2.1);
                                                </p><p>15.1.24. "Website“ means the <a target="_blank" href="https://ofpfunding.com">www.ofpfunding.com </a>website (as set out in the introductory provisions).
                                                </p>
                                            </div>
                                                <p>15.2. For the purposes of the Agreement, the following expressions and abbreviations shall have the following meanings:</p>
                                                <div>
                                                    15.2.1. “EUR” means the euro;
                                                    <p>15.2.2. “USD” means the United States dollar;</p>
                                                    <p>15.2.3. “GBP” means the British pound;</p>
                                                    <p> 15.2.4. “CAD” means the Canadian dollar;</p>
                                                    <p>15.2.5. “AUD” means the Australian dollar;</p>
                                                </div>
                                                <br/>
                                                <div>
                                                <h5>Schedule A</h5>
                                                <p>Models:</p>
                                                <ul>
                                                    <li>Monthly 26%</li>
                                                    <li>Monthly 40%</li>
                                                    <li>Monthly 60%</li>
                                                    <li>Monthly 80%</li>
                                                </ul>
                                                <p>Size:</p>
                                                <ul>
                                                    <li>5,000</li>
                                                    <li>10,000</li>
                                                    <li>25,000</li>
                                                    <li>50,000</li>
                                                    <li>100,000</li>
                                                </ul>
                                                <p>Currencies:</p>
                                                <ul>
                                                    <li>EUR</li>
                                                    <li>USD</li>
                                                    <li>GBP</li>
                                                </ul>
                                            </div>
                                            </div>

                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                                </div>
                                <a v-if="!spinnerPay" @click="sendOrder()" type="submit"  :class="paymeth=='open'|| paymeth=='close' ?'btn disabled':'btn btn-primary'">Confirm Order</a>
                                <button v-else class="btn btn-primary" type="button" disabled>
                                    <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                    <span role="status">Loading...</span>
                                </button>
                                <div v-if="orderErrors.length" class="mt-2">
                                    <h4 class="text-danger fw-bold">One or more errors occured during processing order:</h4>
                                    <ul>
                                        <li v-for="err of orderErrors" :key="err">
                                            <span class="text-danger">{{ err }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div v-if="paymeth=='open'"  class="col-12 d-flex flex-column mb-5 border border-1 border-white-50 p-xl-5 rounded flexdisplay">
                        <h2 class="text-white fw-semibold mb-3">Payment Methods :</h2>
                        <!-- <div class="p-2 mb-5" v-if="reward?.total > 0">
                            <div class="d-flex flex-column gap-1 mb-3">
                                    <h3 class="m-0 text-primary">Your reward points</h3>
                                    <h6 class="m-0 p-0">Use your points to get a discount</h6>
                                    <h4 class="text-danger fw-bold">{{ pointstext }}</h4>
                            </div>
                            <div >
                                <label for="rangeFidelity" class="form-label fs-6 fw-bold">{{ rewardPoints }}/ {{ reward.total }} = {{ (rewardPoints/40).toFixed(2)  }} &pound; </label>
                                <input
                                step="1"
                                type="range"
                                class="form-range"
                                min="0"
                                :max="reward.total"
                                id="rangeFidelity"
                                v-model="rewardPoints"
                                >
                            </div>
                        </div> -->

                        <div v-if="false" class="btn d-flex align-items-center justify-content-between p-xl-4 mb-4 flexdisplay" style="height: 50px;" @click="payWith('stripe')" >
                            <div class="d-flex align-items-center flexdisplay" >
                            <img class="me-4 " style="height: 50px" src="../../../public/assets/images/stripe.png" alt="">

                           </div>
                            <div class="d-flex gap-2 align-items-center">
                                <h4 class="text-uppercase text-white m-0 p-0"> Stripe</h4>
                                <font-awesome-icon class="d-none d-xl-block" :icon="['fas', 'credit-card']" />
                            </div>
                        </div>
                        <div class="btn d-flex align-items-center justify-content-between p-xl-4 mb-4 flexdisplay" style="height: 50px" @click="payWith('trustPayment')">
                           <div class="d-flex align-items-center flexdisplay"  >
                                <form id="form" method="POST" action="https://payments.securetrading.net/process/payments/choice">
                                    <input type="hidden" name="sitereference" :value="this.$trustpayment_domain">
                                    <input type="hidden" name="stprofile" value="default">
                                    <input type="hidden" name="currencyiso3a" value="USD">
                                    <input type="hidden" name="mainamount" v-model="formTot">
                                    <input type="hidden" name="version" value="2">
                                    <input type="hidden" name="ruleidentifier" value="STR-10">
                                    <input type="hidden" name="allurlnotification" value="https://ofp-api.ait-tech.it/api/webhook/payment-gateway?gateway=trustpayment">
                                    <input type="hidden" name="orderreference" v-model="formId">
                                    <input type="submit" class="d-none btn" >
                                </form>
                                <div class="d-flex gap-3 align-items-center">
                                        <img class="me-4 " style="height: 60px" src="/assets/images/trustwhite.png" alt="">
                                </div>
                           </div>
                           <div class="d-flex gap-2 align-items-center">
                            <h4 class="text-uppercase text-white m-0 p-0"> Trustpayment</h4>
                                <font-awesome-icon class="d-none d-xl-block" :icon="['fas', 'credit-card']" />
                            </div>
                        </div>
                        <div class="btn d-flex align-items-center justify-content-between p-xl-4 mb-4 flexdisplay" style="height: 50px"  @click="payWith('coinbase')">
                            <div class="d-flex align-items-center flexdisplay">
                                <img class="me-4 " style="height: 50px;" src="/assets/images/coinbase.png" alt="">

                           </div>
                            <div class="d-flex gap-2 align-items-center">
                                <h4 class="text-uppercase text-white m-0 p-0"> Cryptocurrency</h4>
                                <font-awesome-icon class="d-none d-xl-block" :icon="['fas', 'coin']" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--- End Content --->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <div v-if="paymeth=='close'" class="w-100 bg-success d-flex flex-column mb-5 border border-1 border-white-50 p-xl-5 rounded flexdisplay position-absolute top-50 text-center">
            <h2 class="text-white fw-bold mb-3">Payment Success :</h2>
            <p class="text-white fw-semibold">Go to see your <a href="/orders">Orders</a> </p>
        </div>
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- =============================================================== -->
    <Footer/>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
</div>
</template>

<script>
import bannerKyc from "./statics/bannerKyc.vue";
import Sidebar from "./Sidebar";
import Navbar from "./statics/navbar";
import Footer from "./statics/footer";
import Spinner from "./Spinner"
export default {
    components: {Footer, Navbar, Sidebar, Spinner,bannerKyc},
    data(){
        return{
            rewardPoints:0,
            isChecked2:false,
            coupon:'',
            loading:false,
            options: [
         { value: 'AF', label: 'Afghanistan'},
         { value: 'AX', label: 'Aland Islands'},
         { value: 'AL', label: 'Albania'},
         { value: 'DZ', label: 'Algeria'},
         { value: 'AS', label: 'American Samoa'},
         { value: 'AD', label: 'Andorra'},
         { value: 'AO', label: 'Angola'},
         { value: 'AI', label: 'Anguilla'},
         { value: 'AQ', label: 'Antarctica'},
         { value: 'AG', label: 'Antigua And Barbuda'},
         { value: 'AR', label: 'Argentina'},
         { value: 'AM', label: 'Armenia'},
         { value: 'AW', label: 'Aruba'},
         { value: 'AU', label: 'Australia'},
         { value: 'AT', label: 'Austria'},
         { value: 'AZ', label: 'Azerbaijan'},
         { value: 'BS', label: 'Bahamas'},
         { value: 'BH', label: 'Bahrain'},
         { value: 'BD', label: 'Bangladesh'},
         { value: 'BB', label: 'Barbados'},
         { value: 'BY', label: 'Belarus'},
         { value: 'BE', label: 'Belgium'},
         { value: 'BZ', label: 'Belize'},
         { value: 'BJ', label: 'Benin'},
         { value: 'BM', label: 'Bermuda'},
         { value: 'BT', label: 'Bhutan'},
         { value: 'BO', label: 'Bolivia'},
         { value: 'BA', label: 'Bosnia And Herzegovina'},
         { value: 'BW', label: 'Botswana'},
         { value: 'BV', label: 'Bouvet Island'},
         { value: 'BR', label: 'Brazil'},
         { value: 'IO', label: 'British Indian Ocean Territory'},
         { value: 'BN', label: 'Brunei Darussalam'},
         { value: 'BG', label: 'Bulgaria'},
         { value: 'BF', label: 'Burkina Faso'},
         { value: 'BI', label: 'Burundi'},
         { value: 'KH', label: 'Cambodia'},
         { value: 'CM', label: 'Cameroon'},
         { value: 'CA', label: 'Canada'},
         { value: 'CV', label: 'Cape Verde'},
         { value: 'KY', label: 'Cayman Islands'},
         { value: 'CF', label: 'Central African Republic'},
         { value: 'TD', label: 'Chad'},
         { value: 'CL', label: 'Chile'},
         { value: 'CN', label: 'China'},
         { value: 'CX', label: 'Christmas Island'},
         { value: 'CC', label: 'Cocos (Keeling) Islands'},
         { value: 'CO', label: 'Colombia'},
         { value: 'KM', label: 'Comoros'},
         { value: 'CG', label: 'Congo'},
         { value: 'CD', label: 'Congo}, Democratic Republic'},
         { value: 'CK', label: 'Cook Islands'},
         { value: 'CR', label: 'Costa Rica'},
         { value: 'CI', label: 'Cote D\'Ivoire'},
         { value: 'HR', label: 'Croatia'},
         { value: 'CU', label: 'Cuba'},
         { value: 'CY', label: 'Cyprus'},
         { value: 'CZ', label: 'Czech Republic'},
         { value: 'DK', label: 'Denmark'},
         { value: 'DJ', label: 'Djibouti'},
         { value: 'DM', label: 'Dominica'},
         { value: 'DO', label: 'Dominican Republic'},
         { value: 'EC', label: 'Ecuador'},
         { value: 'EG', label: 'Egypt'},
         { value: 'SV', label: 'El Salvador'},
         { value: 'GQ', label: 'Equatorial Guinea'},
         { value: 'ER', label: 'Eritrea'},
         { value: 'EE', label: 'Estonia'},
         { value: 'ET', label: 'Ethiopia'},
         { value: 'FK', label: 'Falkland Islands (Malvinas)'},
         { value: 'FO', label: 'Faroe Islands'},
         { value: 'FJ', label: 'Fiji'},
         { value: 'FI', label: 'Finland'},
         { value: 'FR', label: 'France'},
         { value: 'GF', label: 'French Guiana'},
         { value: 'PF', label: 'French Polynesia'},
         { value: 'TF', label: 'French Southern Territories'},
         { value: 'GA', label: 'Gabon'},
         { value: 'GM', label: 'Gambia'},
         { value: 'GE', label: 'Georgia'},
         { value: 'DE', label: 'Germany'},
         { value: 'GH', label: 'Ghana'},
         { value: 'GI', label: 'Gibraltar'},
         { value: 'GR', label: 'Greece'},
         { value: 'GL', label: 'Greenland'},
         { value: 'GD', label: 'Grenada'},
         { value: 'GP', label: 'Guadeloupe'},
         { value: 'GU', label: 'Guam'},
         { value: 'GT', label: 'Guatemala'},
         { value: 'GG', label: 'Guernsey'},
         { value: 'GN', label: 'Guinea'},
         { value: 'GW', label: 'Guinea-Bissau'},
         { value: 'GY', label: 'Guyana'},
         { value: 'HT', label: 'Haiti'},
         { value: 'HM', label: 'Heard Island & Mcdonald Islands'},
         { value: 'VA', label: 'Holy See (Vatican City State)'},
         { value: 'HN', label: 'Honduras'},
         { value: 'HK', label: 'Hong Kong'},
         { value: 'HU', label: 'Hungary'},
         { value: 'IS', label: 'Iceland'},
         { value: 'IN', label: 'India'},
         { value: 'ID', label: 'Indonesia'},
         { value: 'IR', label: 'Iran}, Islamic Republic Of'},
         { value: 'IQ', label: 'Iraq'},
         { value: 'IE', label: 'Ireland'},
         { value: 'IM', label: 'Isle Of Man'},
         { value: 'IL', label: 'Israel'},
         { value: 'IT', label: 'Italia'},
         { value: 'JM', label: 'Jamaica'},
         { value: 'JP', label: 'Japan'},
         { value: 'JE', label: 'Jersey'},
         { value: 'JO', label: 'Jordan'},
         { value: 'KZ', label: 'Kazakhstan'},
         { value: 'KE', label: 'Kenya'},
         { value: 'KI', label: 'Kiribati'},
         { value: 'KR', label: 'Korea'},
         { value: 'KW', label: 'Kuwait'},
         { value: 'KG', label: 'Kyrgyzstan'},
         { value: 'LA', label: 'Lao People\'s Democratic Republic'},
         { value: 'LV', label: 'Latvia'},
         { value: 'LB', label: 'Lebanon'},
         { value: 'LS', label: 'Lesotho'},
         { value: 'LR', label: 'Liberia'},
         { value: 'LY', label: 'Libyan Arab Jamahiriya'},
         { value: 'LI', label: 'Liechtenstein'},
         { value: 'LT', label: 'Lithuania'},
         { value: 'LU', label: 'Luxembourg'},
         { value: 'MO', label: 'Macao'},
         { value: 'MK', label: 'Macedonia'},
         { value: 'MG', label: 'Madagascar'},
         { value: 'MW', label: 'Malawi'},
         { value: 'MY', label: 'Malaysia'},
         { value: 'MV', label: 'Maldives'},
         { value: 'ML', label: 'Mali'},
         { value: 'MT', label: 'Malta'},
         { value: 'MH', label: 'Marshall Islands'},
         { value: 'MQ', label: 'Martinique'},
         { value: 'MR', label: 'Mauritania'},
         { value: 'MU', label: 'Mauritius'},
         { value: 'YT', label: 'Mayotte'},
         { value: 'MX', label: 'Mexico'},
         { value: 'FM', label: 'Micronesia}, Federated States Of'},
         { value: 'MD', label: 'Moldova'},
         { value: 'MC', label: 'Monaco'},
         { value: 'MN', label: 'Mongolia'},
         { value: 'ME', label: 'Montenegro'},
         { value: 'MS', label: 'Montserrat'},
         { value: 'MA', label: 'Morocco'},
         { value: 'MZ', label: 'Mozambique'},
         { value: 'MM', label: 'Myanmar'},
         { value: 'NA', label: 'Namibia'},
         { value: 'NR', label: 'Nauru'},
         { value: 'NP', label: 'Nepal'},
         { value: 'NL', label: 'Netherlands'},
         { value: 'AN', label: 'Netherlands Antilles'},
         { value: 'NC', label: 'New Caledonia'},
         { value: 'NZ', label: 'New Zealand'},
         { value: 'NI', label: 'Nicaragua'},
         { value: 'NE', label: 'Niger'},
         { value: 'NG', label: 'Nigeria'},
         { value: 'NU', label: 'Niue'},
         { value: 'NF', label: 'Norfolk Island'},
         { value: 'MP', label: 'Northern Mariana Islands'},
         { value: 'NO', label: 'Norway'},
         { value: 'OM', label: 'Oman'},
         { value: 'PK', label: 'Pakistan'},
         { value: 'PW', label: 'Palau'},
         { value: 'PS', label: 'Palestinian Territory}, Occupied'},
         { value: 'PA', label: 'Panama'},
         { value: 'PG', label: 'Papua New Guinea'},
         { value: 'PY', label: 'Paraguay'},
         { value: 'PE', label: 'Peru'},
         { value: 'PH', label: 'Philippines'},
         { value: 'PN', label: 'Pitcairn'},
         { value: 'PL', label: 'Poland'},
         { value: 'PT', label: 'Portugal'},
         { value: 'PR', label: 'Puerto Rico'},
         { value: 'QA', label: 'Qatar'},
         { value: 'RE', label: 'Reunion'},
         { value: 'RO', label: 'Romania'},
         { value: 'RU', label: 'Russian Federation'},
         { value: 'RW', label: 'Rwanda'},
         { value: 'BL', label: 'Saint Barthelemy'},
         { value: 'SH', label: 'Saint Helena'},
         { value: 'KN', label: 'Saint Kitts And Nevis'},
         { value: 'LC', label: 'Saint Lucia'},
         { value: 'MF', label: 'Saint Martin'},
         { value: 'PM', label: 'Saint Pierre And Miquelon'},
         { value: 'VC', label: 'Saint Vincent And Grenadines'},
         { value: 'WS', label: 'Samoa'},
         { value: 'SM', label: 'San Marino'},
         { value: 'ST', label: 'Sao Tome And Principe'},
         { value: 'SA', label: 'Saudi Arabia'},
         { value: 'SN', label: 'Senegal'},
         { value: 'RS', label: 'Serbia'},
         { value: 'SC', label: 'Seychelles'},
         { value: 'SL', label: 'Sierra Leone'},
         { value: 'SG', label: 'Singapore'},
         { value: 'SK', label: 'Slovakia'},
         { value: 'SI', label: 'Slovenia'},
         { value: 'SB', label: 'Solomon Islands'},
         { value: 'SO', label: 'Somalia'},
         { value: 'ZA', label: 'South Africa'},
         { value: 'GS', label: 'South Georgia And Sandwich Isl.'},
         { value: 'ES', label: 'Spain'},
         { value: 'LK', label: 'Sri Lanka'},
         { value: 'SD', label: 'Sudan'},
         { value: 'SR', label: 'Suriname'},
         { value: 'SJ', label: 'Svalbard And Jan Mayen'},
         { value: 'SZ', label: 'Swaziland'},
         { value: 'SE', label: 'Sweden'},
         { value: 'CH', label: 'Switzerland'},
         { value: 'SY', label: 'Syrian Arab Republic'},
         { value: 'TW', label: 'Taiwan'},
         { value: 'TJ', label: 'Tajikistan'},
         { value: 'TZ', label: 'Tanzania'},
         { value: 'TH', label: 'Thailand'},
         { value: 'TL', label: 'Timor-Leste'},
         { value: 'TG', label: 'Togo'},
         { value: 'TK', label: 'Tokelau'},
         { value: 'TO', label: 'Tonga'},
         { value: 'TT', label: 'Trinidad And Tobago'},
         { value: 'TN', label: 'Tunisia'},
         { value: 'TR', label: 'Turkey'},
         { value: 'TM', label: 'Turkmenistan'},
         { value: 'TC', label: 'Turks And Caicos Islands'},
         { value: 'TV', label: 'Tuvalu'},
         { value: 'UG', label: 'Uganda'},
         { value: 'UA', label: 'Ukraine'},
         { value: 'AE', label: 'United Arab Emirates'},
         { value: 'GB', label: 'United Kingdom'},
         { value: 'US', label: 'United States'},
         { value: 'UM', label: 'United States Outlying Islands'},
         { value: 'UY', label: 'Uruguay'},
         { value: 'UZ', label: 'Uzbekistan'},
         { value: 'VU', label: 'Vanuatu'},
         { value: 'VE', label: 'Venezuela'},
         { value: 'VN', label: 'Viet Nam'},
         { value: 'VG', label: 'Virgin Islands}, British'},
         { value: 'VI', label: 'Virgin Islands}, U.S.'},
         { value: 'WF', label: 'Wallis And Futuna'},
         { value: 'EH', label: 'Western Sahara'},
         { value: 'YE', label: 'Yemen'},
         { value: 'ZM', label: 'Zambia'},
         { value: 'ZW', label: 'Zimbabwe'}
            ],
            firstName:'',
            lastName:'',
            address:'',
            city:'',
            phone:'',
            birth:'',
            zip:'',
            email:'',
            id:'',
            country:'',
            client:[],
            textErrCat:'',
            paymeth:'start',
            checkText:'',
            items:[],
            orderID:'',
            apiUrl:'',
            //reward:[],
            //pointstext:'',
            formTot:0,
            formId:'',
            orderPlat:'',
            //textRespCupon:'',
            //validCupon:null,
            //percCoupon:'',
            //typeCoupon:'',
            orderErrors: [],
            orders:[],
            total:0,
            spinnerPay:false
        }
    },
    methods:{
        formatCurrency(curr,value){
        const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: curr,
                    minimumFractionDigits: 2,
                    });
                    return formatter.format(value);
        },
        // removeElement(item ){
        //     axios.post(`${this.$baseUrl}/ecommerce/cart/removeItemCart`, {
        //                 "customer_id": this.id,
        //                 "item": item
        //             }).then((response)=>{
        //                 try {
        //                     window.sendinblue.track(
        //                         'cart_deleted',
        //                         {
        //                             email: this.client.email,
        //                             FIRSTNAME: this.client.firstName,
        //                             LASTNAME: this.client.lastName
        //                         }
        //                     );
        //                 } catch (e) {
        //                     console.error(e);
        //                 }
        //                 this.changeStatus='remove'
        //                 this.order=''
        //                 this.textErrCat=''
        //                 this.paymeth='start'
        //                 this.isChecked2=false
        //                 axios.get(`${this.$baseUrl}/ecommerce/cart/getCart?customer_id=${this.id}`).then((response)=>{
        //             if (response.data.items.length === 0) {
        //                 // quando non ha items nel carrello torna indietro di una pagina
        //                 this.$router.push({ path: '/buy-account',})
        //                 return;
        //             }
        //         })
        //             })
        // },
        clientInfo(){
            axios.get(`${this.$baseUrl}/customers/getCustomerInfo?email=${this.$cookies.get('emailAccount')}`).then((response)=>{
                this.client= response.data
                this.firstName=this.client.firstName
                this.lastName=this.client.lastName
                this.address=this.client.address
                this.zip=this.client.zip
                this.city=this.client.city
                this.country=this.client.country
                this.phone=this.client.phone
                this.birth= this.client.birthday
                this.id=this.client._id
                this.email=this.client.email
                let itemsInCart = localStorage.getItem('cartBundle');
                let parsedItems = JSON.parse(itemsInCart);
                this.orders=parsedItems.items;
                this.orders.forEach(element => {
                    this.total+=element.priceBundle
                });
                console.log(this.orders)
            })
        },
        sendOrder(){
            this.loading=true;
            this.checkText=''
            if(this.isChecked2){
                this.loading=true;
                let orderConfirm={
                    "customer_id":this.id,
                    "billing_details": {
                    "address": this.address,
                    "city": this.city,
                    "country": this.country,
                    "name": this.firstName,
                    "surname": this.lastName,
                    "zip": this.zip
                    }
                }
                let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: `${this.$baseUrl}/ecommerce/cart/confirmOFPOrder`,
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data : orderConfirm
                    };
                    axios.request(config)
                        .then(response => {
                            this.orderID = response.data.id
                            this.paymeth = response.data.total <= 0 ? 'close' : 'open'
                            this.checkText = '';
                        })
                        .catch(err => {
                            const { errors } = err.response.data;
                            if (!errors) {
                                console.error(err);
                                return;
                            }

                            this.orderErrors = errors;
                        });
            } else if(!this.isChecked2){
                this.checkText="The acknowledgment of the terms and conditions of purchase is required"
            }
            this.loading=false;
        },
        payWith(value){
            this.spinnerPay=true
            this.pointstext=''
            let payMethods={
                "order_id":this.orderID,
                "payment_type":value,
                "use_points":this.rewardPoints
            }
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${this.$baseUrl}/ecommerce/order/pay`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data : payMethods
            };
            axios.request(config).then((response) => {
                this.spinnerPay=false
                console.log('response api ===')
                console.log(response)
                if (response.data === 'Not enough points') {
                    this.pointstext = response.data;
                    this.getReward()
                } else {
                    try {
                        window.sendinblue.track(
                            'order_completed',
                            {
                                email: this.client.email,
                                FIRSTNAME: this.client.firstName,
                                LASTNAME: this.client.lastName
                            }
                        );
                    } catch (e) {
                        console.error(e);
                    }
                    if (value === 'stripe') {
                        window.open(response.data.url, "_blank");
                    } else if (value === 'coinbase') {
                        window.open(response.data.url, "_blank");
                    } else if (value === 'trustPayment') {
                        console.log(response)
                        this.formTot = response.data.total;
                        this.formId = response.data.OFPPaymentId;
                        if (this.formId !== '' && this.formTot !== 0) {
                            this.$forceUpdate();
                            this.$nextTick(() => {
                                this.sendForm();
                            });
                        }
                    }
                }
            }).catch((error) => {
                console.error("Errore durante la chiamata axios:", error);
            });
        },
        sendForm(){
            let form = document.getElementById('form')
            form.submit()
        },
        clearCart(){
            axios.post(`${this.$baseUrl}/ecommerce/cleanCart`, {
                    "customer_id": this.id,
                }).then((response) => {
                    localStorage.removeItem('cartBundle')
                    console.log(response)
                    this.$router.push({ path: '/home',})
                }).catch((error) => {
                    console.error(error);
                });
        }
    },
    mounted(){
        this.clientInfo()
    },
    beforeMount(){
        if(!this.$cookies.get('emailAccount')){
            this.$router.push({ path: '/',})
        }
    }

}
</script>

<style scoped>
   .form-control{
    background-color: transparent!important;
    border: 1px solid #868789;
    padding: 5px;
    }
    option{
        background-color: #0d0b27;
        color: white;
    }
    br{
        margin: 4px, 0px;
    }
    .inputCoupon{
        background-color: transparent!important;
        border: 1px solid #868789;
        color: white;
    }
</style>
