<template >
  <div class="spinner-container">
    <div class="spinner">
      <img src="/assets/images/OFP_white.png" alt="logo" />
      <span class="loader"></span>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.spinner-container {
  display: block;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  opacity: 0.8;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  color: white;
  font-size: 2rem;
  flex-direction: column;
  background-image: url(/assets/images/sfondo.jpg); 
  background-size: cover; 
  background-attachment: fixed; 
  min-height: 100vh
}
.spinner {
  position: relative;
  width: 120px;
  height: 120px;
}
.spinner img {
  position: absolute;
  z-index: 60;
  width: 120px;
}
.loader {
  display: block;
  position: absolute;
  width: 140px;
  height: 140px;
}
.loader:before , .loader:after{
  content: '';
  border-radius: 50%;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset;
}
.loader:after {
  box-shadow: 0 2px 0 #FF3D00 inset;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {  transform: rotate(0)}
  100% { transform: rotate(360deg)}
}

</style>
