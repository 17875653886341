<template>
    <Spinner v-if="loading"/>
      <embed :src="pdfSource" type="application/pdf" width="100%" height="1267px" />
  </template>

  <script>
    import axios from 'axios';
    import Spinner from "./Spinner";

  export default {
    components:{Spinner},
    data() {
      return {
        loading:true,
        hash:'',
        pdfBuffer: null,
        pdfSource: null
      };
    },
    methods: {
      async fetchPDF() {
        try {
            this.hash=this.$route.params.hash;
          const response = await axios.get(`${this.$baseUrl}/certificates/pdf?hash=${this.hash}`, {
            responseType: 'arraybuffer'
          });

          // Converte il buffer in base64
          const base64Pdf = Buffer.from(response.data, 'binary').toString('base64');

          // Costruisci l'URL dati del PDF
          this.pdfSource = 'data:application/pdf;base64,' + base64Pdf;
          this.loading=false
        } catch (error) {
          console.error('Errore durante il recupero del PDF:', error);
        }
      }
    },
    mounted() {
      this.fetchPDF();
    }
  };
  </script>
