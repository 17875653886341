<template>
    <div class="w-100">
        <div class="row gap justify-content-center">
            <div class="col-xs-12 selector-box">
                <h4 class="mb-0">
                    {{ $t("buyaccount-type-of-payout") }}
                    <img src="../../../../internal/black-dashboard-pro-v1.1.1/assets/img/info.png"
                        :title="$t('filterCustomPayout')" alt="Info" class="inline-icon ms-2"
                        @click="toggleTooltip($event, 'tooltip1')" />
                </h4>
                <div class="col-xs-12 justify-content-between gap-2 text-center">
                    <a class="col button" v-for="(item, index) of payout_frequencies" :key="index"
                        :class="{ active: modelValue.payout_frequency === item }" aria-current="page"
                        :id="`nav-${item}-tab`" data-bs-toggle="tab" type="button" role="tab" aria-selected="true"
                        href="#" @click="onUpdatePayoutFrequency(item)">
                        {{ formatPayoutFrequency(item) }}
                    </a>
                </div>
            </div>
            <div class="col-xs-12 selector-box">

                <h4 class="mb-0">
                    {{ $t("buyaccount-currency") }}
                    <img src="../../../../internal/black-dashboard-pro-v1.1.1/assets/img/info.png"
                        :title="$t('filterCustomCurrency')" alt="Info" class="inline-icon ms-2"
                        @click="toggleTooltip($event, 'tooltip2')" />
                </h4>

                <div class="col-xs-12 justify-content-between gap-2 text-center">
                    <a class="col button" v-for="(item, index) of currencies" :key="index"
                        :class="{ active: modelValue.currency === item }" aria-current="page" :id="`nav-${item}-tab`"
                        data-bs-toggle="tab" type="button" role="tab" aria-selected="true" href="#"
                        @click="onUpdateCurrency(item)">
                        {{ formatCurrency(item) }}
                    </a>
                </div>
            </div>
        </div>
        <div class="row mt-md-4 gap-3 justify-content-center slider-container">
            <div class="col selector-box">

                <h4 class="mb-0">
                    {{ $t("buyaccount-account-size") }}
                    <img src="../../../../internal/black-dashboard-pro-v1.1.1/assets/img/info.png"
                        :title="$t('filterCustomSize')" alt="Info" class="inline-icon ms-2"
                        @click="toggleTooltip($event, 'tooltip3')" />
                </h4>

                <div class="slider-wrapper">
                    <Slider v-model="capital" :data="capitals.map((e) => `${e / 1000}K`)" adsorb contained :marks="true"
                        tooltip="hover" />
                </div>
            </div>
            <div class="col selector-box">

                <h4 class="mb-0">
                    {{ $t("max_daily_drawdown") }}
                    <img src="../../../../internal/black-dashboard-pro-v1.1.1/assets/img/info.png"
                        :title="$t('filterCustomDD')" alt="Info" class="inline-icon ms-2"
                        @click="toggleTooltip($event, 'tooltip4')" />
                </h4>


                <div class="slider-wrapper">
                    <Slider v-model="drawdown" :data="drawdowns.map((e) => `${e}%`)" adsorb contained marks
                        tooltip="hover" />
                </div>
            </div>
            <div class="col selector-box">

                <h4 class="mb-0">
                    {{ $t("profit_split") }}
                    <img src="../../../../internal/black-dashboard-pro-v1.1.1/assets/img/info.png"
                        :title="$t('filterCustomProfit')" alt="Info" class="inline-icon ms-2"
                        @click="toggleTooltip($event, 'tooltip5')" />
                </h4>

                <div class="slider-wrapper">
                    <Slider v-model="profit_split" :data="profit_splits.map((e) => `${e}%`)" adsorb contained marks
                        tooltip="hover" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import './slider/styles/filters.scss'
import { defineEmits, defineProps, unref, computed, ref } from "vue";
import Slider from "./Slider.vue";
import {
    formatCurrency,
    formatPayoutFrequency,
} from "./utils/format";

const props = defineProps({
    modelValue: { required: true },
    capitals: Array,
    drawdowns: Array,
    profit_splits: Array,
    payout_frequencies: Array,
    currencies: Array,
});

const emit = defineEmits(["update:modelValue"]);

const capital = computed({
    get: () => `${props.modelValue.capital / 1000}K`,
    set: (val) =>
        emit("update:modelValue", {
            ...props.modelValue,
            capital: parseInt(val.replace("K", "") * 1000),
        }),
});

const drawdown = computed({
    get: () => `${props.modelValue.drawdown}%`,
    set: (val) =>
        emit("update:modelValue", {
            ...props.modelValue,
            drawdown: parseInt(val.replace("%", "")),
        }),
});

const profit_split = computed({
    get: () => `${props.modelValue.profit_split}%`,
    set: (val) =>
        emit("update:modelValue", {
            ...props.modelValue,
            profit_split: parseInt(val.replace("%", "")),
        }),
});

/* Tooltip */
const activeTooltips = new Set();
const toggleTooltip = (event, _tooltipId) => {
    const tooltipElement = event.target;
    if (activeTooltips.has(tooltipElement)) {
        const bsTooltip = bootstrap.Tooltip.getInstance(tooltipElement);
        bsTooltip?.hide();
        activeTooltips.delete(tooltipElement);
        return;
    }
    const bsTooltip = new bootstrap.Tooltip(tooltipElement, {
        trigger: "manual",
        placement: "right",
    });
    bsTooltip.show();
    activeTooltips.add(tooltipElement);
    const clickOutsideListener = (e) => {
        if (!tooltipElement.contains(e.target)) {
            bsTooltip.hide();
            activeTooltips.delete(tooltipElement);
            document.removeEventListener("click", clickOutsideListener);
        }
    };
    document.addEventListener("click", clickOutsideListener);
};

const onUpdatePayoutFrequency = (val) => {
    emit("update:modelValue", {
        ...unref(props.modelValue),
        ...{ payout_frequency: val },
    });
};

const onUpdateCurrency = (val) => {
    emit("update:modelValue", {
        ...unref(props.modelValue),
        ...{ currency: val },
    });
};

</script>

<style scoped lang="scss">
.josefin-sans {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.box {
    max-width: fit-content;
    min-width: 390px;
    color: white;
    padding-left: 24px;
    padding-right: 24px;
}

.selector-box {
    min-width: 390px;
    max-width: 390px;
    padding: 24px;
    text-align: left;
    color: white;
    min-height: 130px;

    @media screen and (max-width: 767.9px) {
        min-width: 270px;
    }

    .col-xs-12 {
        display: flex;
    }

    .button {
        text-align: center;
        white-space: nowrap;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 18px;
        font-family: "Josefin Sans", sans-serif;
        font-optical-sizing: auto;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        max-width: 109px;
        min-width: fit-content;
        line-height: 34px;
        color: white;
        background: rgba(255, 255, 255, 0.05);

        &.active {
            background: rgb(255, 104, 68, 1);
        }

        @media screen and (max-width: 767.9px) {
            border-radius: 6px;
            max-width: 90px;
        }
    }

    @media screen and (max-width: 767.9px) {
        padding: 14px;
        min-height: unset;
    }

    h4 {
        font-size: 24px;
        line-height: 24px;
        font-weight: bolder;
        font-family: "Josefin Sans", sans-serif;
        padding-bottom: 10px;

        @media screen and (max-width: 767.9px) {
            font-size: 14px;
            text-align: center;
        }
    }
}

.slider-container .selector-box {
    height: 144px;

    @media screen and (max-width: 767.98px) {
        height: fit-content;
    }
}

.slider-wrapper {
    padding-top: 36px;
    padding-left: 4px;
    padding-right: 4px;

    @media screen and (max-width: 767.98px) {
        padding-top: 0;
    }
}
</style>
