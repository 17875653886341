export default {
    install: (app, options) => {
        app.config.globalProperties.$trackKlaviyoEvent = (event, customer, data) => {
            if (location.hostname.includes('dev') || location.hostname === '127.0.0.1' || location.hostname === 'localhost' || location.hostname.endsWith('.test')) {
                return;
            }

            const headers = {
                accept: 'application/json',
                'content-type': 'application/json',
                revision: '2024-06-15',
            };
            const payload = {
                data: {
                    type: 'event',
                    attributes: {
                        properties: {
                            ...data
                        },
                        value: data.total || data.value || 0,
                        value_currency: 'USD',
                        metric: {
                            data: {
                                type: 'metric',
                                attributes: {
                                    name: event,
                                }
                            }
                        },
                        profile: {
                            data: {
                                type: 'profile',
                                attributes: {
                                    email: customer.email || customer,
                                    first_name: customer.FIRSTNAME,
                                    last_name: customer.LASTNAME
                                }
                            }
                        }
                    },
                }
            }
            axios.post('https://a.klaviyo.com/client/events/?company_id=R4jAAt', payload, { headers });
        };

        app.provide('$trackKlaviyoEvent', app.config.globalProperties.$trackKlaviyoEvent);
    }
}
