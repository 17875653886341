<template>
  <section class="col-lg-6 p-5 image-container d-none d-lg-flex">
    <img class="img-fluid p-5" src="/assets/images/imgLoginRegister.jpg" alt="">
    <slot>
    </slot>
  </section>
</template>
<style lang="scss" scoped>
  .image-container {
    background-color: #03081c;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
</style>