<template>
    <div id="main-wrapper">
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar" >
            <Navbar :stop="stopnav" />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar" >
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar" >
                <!-- Sidebar navigation-->
                <Sidebar @rank="watchRank" />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->

        <div class="page-wrapper mb-3" :class="showRank?'opacity-25':''" >
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!--- Start Content --->
                <div class="row mt-5 pb-5">
                    <div class="">
                            <div class="">
                                <h2 class="card-title text-white fw-bold ">{{ $t("Payouts") }}</h2>
                            </div>
                    </div>
                    <div class="gap-4 my-3 d-flex row">
                        <div class="col-12 d-flex justify-content-between">
                            <!--- Select Months --->
                            <select id="monthSelect" v-model="selectedMonth" @change="updateSelectedMonth">
                                <option :value="{ label: 'All' }">Select Month</option>
                                <option v-for="(month, index) in availableMonths" :key="index" :value="month">
                                    {{ month.label }}
                                </option>
                            </select>
                            <!--- Info Payouts --->
                            <div class="d-flex gap-3">
                                <div class="">
                                    <p class="text-white fw-bold p-0 m-0 mb-1">{{ $t('PayoutsTotal') }}</p>
                                    <p class="text-dark p-0 m-0"> + {{ payouts.length }}</p>
                                </div>
                                <div class="border border-1 me-2"></div>
                                <div class="">
                                    <p class="text-white fw-bold p-0 m-0 mb-1">{{ $t('$Payouts') }}</p>
                                    <p class="text-dark p-0 m-0">{{ formatCurrency(totalPayout) }}</p>
                                </div>
                                <div class="border border-1 me-2"></div>
                                <div class="">
                                    <p class="text-white fw-bold p-0 m-0 mb-1">{{ $t('HighestPayouts') }}</p>
                                    <p class="text-dark p-0 m-0">{{ formatCurrency(maxPayout)}}</p>
                                </div>
                                <div class="border border-1 me-2"></div>
                                <div class="">
                                    <p class="text-white fw-bold p-0 m-0 mb-1">AVG {{  $t('Payouts') }}</p>
                                    <p class="text-dark p-0 m-0">{{ formatCurrency(AVGpayouts)}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Column -->
                    <div class="col-lg-12 col-md-12 mt-4">
                        <table class="table table-border table-hover text-white" >
                            <thead class="text-white">
                                <tr class="text-uppercase fs-6">
                                    <th>{{ $t('checkout-name') }}</th>
                                    <th>{{ $t('Status') }}</th>
                                    <th>{{ $t('Total') }}</th>
                                    <th>{{ $t('Size') }}</th>
                                    <th>{{ $t('checkout-country') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(pay) in paginatedPayouts" :key="pay">
                                    <td class="text-capitalize">{{ pay.account.customer.lastName[0] }}. {{ pay.account.customer.firstName }}</td>
                                    <td class="text-success fw-bold ">Paid</td>
                                    <td>{{ pay.payout }}</td>
                                    <td>{{ pay.account.value_account }}</td>
                                    <td v-if="pay.account.customer.country"><img :src="'/assets/icons/flag-icon-css/flags/'+ pay.account.customer.country +'.svg'" alt=""> {{ pay.account.customer.country}}</td>
                                    <td v-else ></td>
                                </tr>
                            </tbody>
                        </table>
                        <nav aria-label="Page navigation example" class="d-flex justify-content-center mt-5" style="padding-bottom: 150px;"  >
                            <ul class="pagination">
                                <li class="page-item ms-auto" :class="{'disabled': currentPage === 1}">
                                    <a class="page-link" href="#" aria-label="Previous" @click.prevent="prevPage">
                                        <span aria-hidden="true">{{ $t('Prev') }}</span>
                                    </a>
                                </li>
                                <li class="page-item me-auto" :class="{'disabled': currentPage === pages.length}">
                                    <a class="page-link" href="#" aria-label="Next" @click.prevent="nextPage">
                                        <span aria-hidden="true">{{ $t('Next') }}</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <!-- Column -->
                </div>
                <!--- End Content --->

            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <RankModal :showRank="showRank" @close="closeRank"/>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer/>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Navbar from "./statics/navbar";
import Footer from "./statics/footer";
import bannerKyc from "./statics/bannerKyc.vue";
import RankModal from "./RankModal";
export default {
    name: "Payouts",
    components: {Footer, Navbar, Sidebar,bannerKyc,RankModal},
    data(){
        return{
            payouts:[],
            currentPage: 1,
            itemsPerPage: 10,
            selectedMonth: { label: 'All' },
            availableMonths: [],
            startDate:'',
            endDate:''
        }
    },
    methods:{
        getPayouts(){
            let call= ''
            if(this.startDate!='' && this.endDate!=''){
                call=`${this.$baseUrl}/enduser/payouts?start_date=${this.startDate}&end_date=${this.endDate}`
            }else{
                call=`${this.$baseUrl}/enduser/payouts`
            }
            axios.get(call).then((response)=>{
                this.payouts=response.data
                this.payouts.forEach((element, id) => {
                    if ( parseInt(element.account.value_account) > 5000 && parseInt(element.account.value_account) < 10000 ) {
                    element.account.value_account = "5000";
                    } else if ( parseInt(element.account.value_account) > 10000 && parseInt(element.account.value_account) < 25000) {
                            element.account.value_account = "10000";
                    } else if ( parseInt(element.account.value_account) > 25000 && parseInt(element.account.value_account) < 50000 ) {
                        element.account.value_account = "25000";
                    } else if (parseInt(element.account.value_account) > 50000 && parseInt(element.account.value_account) < 100000) {
                        element.account.value_account = "50000";
                    } else if ( parseInt(element.account.value_account) > 100000 && parseInt(element.account.value_account) < 200000 ) {
                        element.account.value_account = "100000";
                    } else if ( parseInt(element.account.value_account) > 200000) {
                        element.account.value_account = "200000";
                    }
                })
            })
        },
        formatCurrency(value){
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
            });
            return formatter.format(value);
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        generateMonthOptions() {
            const today = new Date();
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth() + 1;
            for (let year = 2023; year <= currentYear; year++) {
                for (let month = 1; month <= 12; month++) {
                if (year === currentYear && month > currentMonth) {
                    break; // Stop se il mese è nel futuro
                }

                const startDate = `${year}/${month.toString().padStart(2, '0')}/01`;
                const lastDay = new Date(year, month, 0).getDate();
                const endDate = `${year}/${month.toString().padStart(2, '0')}/${lastDay.toString().padStart(2, '0')}`;

                const monthLabel = new Date(startDate).toLocaleDateString('default', { month: 'long', year: 'numeric' });

                this.availableMonths.push({
                    label: monthLabel,
                    startDate,
                    endDate,
                });
                }
            }
        },
        updateSelectedMonth() {
            if(this.selectedMonth.label!="All"){
                this.startDate=this.selectedMonth.startDate
                this.endDate=this.selectedMonth.endDate
                this.getPayouts()
            }else{
                this.startDate=''
                this.endDate=''
                this.getPayouts()
            }
        },
    },
    computed: {
        totalPayout() {
            return this.payouts.reduce((total, payoutObj) => total + payoutObj.payout, 0);
        },
        maxPayout() {
            return this.payouts.reduce((max, payoutObj) => Math.max(max, payoutObj.payout), 0);
        },
        AVGpayouts() {
            if (this.payouts.length === 0) {
                return 0;
            }
            const totalPayout = this.totalPayout;
            return totalPayout / this.payouts.length;
        },
        totalPages() {
            return Math.ceil(this.payouts.length / this.itemsPerPage);
        },
        paginatedPayouts() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.payouts.slice(startIndex, endIndex);
        },
        pages() {
            const pages = [];
            for (let i = 1; i <= this.totalPages; i++) {
                pages.push(i);
            }
            return pages;
        },
    },
    mounted(){
        this.getPayouts(),
        this.generateMonthOptions()
    }
}
</script>

<style scoped>
    select, option{
        background-color: #361727!important;
        border: none;
    }
    img{
        width: 20px;
    }
</style>
