<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div id="liveToast" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header coloreBG">
                <strong class="me-auto fw-bold text-primary">OFP Funding</strong>
            </div>
            <div class="borderColor w-75 m3-auto ms-2"></div>
            <div class="toast-body disFlex d-flex flex-column">
                <strong :class="status?'text-success':'text-danger'">{{ messageToast }}</strong>
                <small>{{ subMessToast }}</small>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props:{
        messageToast:String,
        subMessToast:String,
        status:Boolean
    },
}
</script>

<style scoped>
.toast,
.toast-header,
.toast-body{
    background-color: #080b1e!important;
}
.borderColor{
    border: 0.5px solid #7070702b;
}
.disFlex{
    display: flex!important;
}
</style>
