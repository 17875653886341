<template>
  <div class="card-section p-3">
    <img src="/assets/images/warning.svg" class="py-3" />
    <h2 class="text-white">{{ $t('need-verify-email-title') }}</h2>
    <p class="text-center fw-normal my-4">
      {{ $t('need-verify-email-description') }}
      <span class="text-white fw-semibold">{{ email }}</span>
    </p>
    <a class="text-primary text-decoration-underline mb-4" href="#" @click="resendEmail">{{ $t('resend-email') }}</a>
  </div>
</template>
<script>
import { computed } from 'vue'
  export default {
    props: {
      email: String,
      resendEmail: Function,
    }
  }
</script>
