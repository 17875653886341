<template>
  <div class="row mt-2 mt-lg-0">
    <marquee direction="left" scrollamount="5">
        <ul style="display: flex; column-gap: 30px; align-items: center;" class="m-0 py-2">
            <li v-for="item in carouselItems" :key="item._id"><span class="text-white me-2"> {{ item.symbol }}</span>
                <span :class="item.open>=item.price?'text-success':'text-danger'"> {{ item.price$ }}</span></li>
        </ul>
    </marquee>
</div>
</template>

<script>

export default {
    name: "PriceCarousel",
    data(){
        return{
            carouselItems:[]
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            axios.get(`${this.$baseUrl}/getAllTickers`).then((response)=>{
                response.data.forEach(element => {
                    element.price$=`$ ${element.price}`
                });
                this.carouselItems=response.data
            })
        }
    }
}
</script>

<style scoped>

</style>

