<template>
<div style="height: 100vh;">
    <img src="assets/images/rules.jpg" alt="" style="width: 100vw; height: 100vh;position: absolute; top: 0;">
    <div class="p-5" style="width: 100%; height: 100%;">
        <div class="mb-3" style="position: relative;"><h1 class="fw-bold border_green" >Trading Rules</h1></div>
        <div style="width: 100%;
                    opacity: 85%;
                    display: flex;
                    flex-direction: column;
                    row-gap: 30px;">
        <div class="card mb-0 card_border py-5 w-50">
            <div class="card-body" style="font-size: 20px;">
                <div class="card-text text-white">
                    <div>
                        <h4 class="text-center text-primary text-uppercase mb-4">5% Daily Drawdown</h4>
                        Your equity and balance must not be less or equal than your daily maximum balance minus the account margin. The account margin is 5% of your initial deposit balance. Each day is considered to be starting from midnight GMT +0. Breaking the rule will lead to the account closure.
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-0 card_border py-5 w-50 align-self-center" >
            <div class="card-body" style="font-size: 20px;">
                <div class="card-text text-white">
                    <div>
                        <h4 class="text-center text-primary text-uppercase mb-4 ">10% Total Drawdown</h4>
                        The equity and balance of the trading account must not, at any moment during the account duration, decline below 90% of the initial account balance during the first month. For the subsequent month the overall maximum drawdown should not be greater than 10% of the initial account balance from the previous payout balance. Breaking the rule will lead to the account closure.
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-0 card_border py-5 w-50 align-self-end" >
            <div class="card-body" style="font-size: 20px;">
                <div class="card-text text-white">
                    <div>
                       <h4 class="text-center text-primary text-uppercase mb-4">3 Day/Week </h4>
                       The minimum number of trading days is 3 every 7 days. If a trade is held over multiple days, only the day when the trade was executed is considered to be the trading day. Your first day in the program will be considered the date when you receive the account logins. Breaking the rule will lead to the account closure.
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.card-text>*{
    margin-bottom: 65px;
}
.card-text>*>h4{
    font-weight: 700;
}
.border_green{
    padding-bottom: 3px;
    border-bottom: 5px solid #12FCA8;
}
.card_border{
    border-radius: 25px;
    overflow: hidden;
}

</style>
