<template>
    <body  class="skin-default card-no-border" >
    <section id="wrapper">
        <div class="row h-100 justify-content-start">
            <div class="w-100 d-flex">
                <toastMessage v-if="showToast" :status="registrationStatus" :messageToast="message" :subMessToast="subMessage"/>
                <div class="d-flex flex-column col-12 col-lg-7">
                    <div class="mt-2 ms-2 mb-5 mb-lg-0 mt-lg-5 ms-lg-5"> <img src="/assets/images/logoScritte.png" ></div>
                        <div class="w-100 d-flex flex-column align-items-center my-auto " >
                        <div class="w-100" >
                            <div v-if="error" class="text-center pb-5">
                                <h3 class="text-danger fw-bold">{{ texterror }}</h3>
                            </div>
                            <form  @submit.prevent="createClient()" class="form-horizontal form-material d-flex flex-column gap-1 col-11 col-lg-8 mx-2 mx-lg-auto">
                                <div class="m-b-20">
                                    <h1 class="text-start fw-bold text-white">{{ $t('Register') }}</h1>
                                    <p>{{ $t('EnterDetails') }}</p>
                                </div>

                                <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                                    <div class="col-12 ">
                                        <label for="firstName">{{ $t('checkout-name') }}</label>
                                        <input class="form-control border border-1 border-white rounded" placeholder="Name" type="text" v-model="firstName" required id="firstName" >
                                    </div>
                                </div>

                                <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                                    <div class="col-12 ">
                                        <label for="lastName">{{ $t('checkout-lastName') }}</label>
                                        <input class="form-control border border-1 border-white rounded" placeholder="last Name" type="text" v-model="lastName" required id="lastName" >
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                                    <div class="col-12 ">
                                        <label for="birthday">{{ $t('Bday') }}</label>
                                        <input class="form-control rounded" v-model="birthday" type="date" required id="birthday" >
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                                    <div class="col-12 col-lg-5">
                                        <label for="address">{{ $t('Address') }}</label>
                                        <input class="form-control border border-1 border-white rounded" placeholder="Address" v-model="address" type="text" required id="address" >
                                    </div>
                                    <div class="col-12 col-lg-5">
                                        <label for="country">{{ $t('checkout-country') }}</label>
                                        <select required name="country" class="form-control text-white border border-1 border-white rounded" id="country" v-model="country">
                                            <option value="0" selected="selected" > </option>
                                            <option value="AX">Åland Islands</option>
                                            <option value="AL">Albania</option>
                                            <option value="DZ">Algeria</option>
                                            <option value="AS">American Samoa</option>
                                            <option value="AD">Andorra</option>
                                            <option value="AO">Angola</option>
                                            <option value="AI">Anguilla</option>
                                            <option value="AQ">Antarctica</option>
                                            <option value="AG">Antigua and Barbuda</option>
                                            <option value="AR">Argentina</option>
                                            <option value="AM">Armenia</option>
                                            <option value="AW">Aruba</option>
                                            <option value="AU">Australia</option>
                                            <option value="AT">Austria</option>
                                            <option value="AZ">Azerbaijan</option>
                                            <option value="BS">Bahamas</option>
                                            <option value="BH">Bahrain</option>
                                            <option value="BD">Bangladesh</option>
                                            <option value="BB">Barbados</option>
                                            <option value="BY">Belarus</option>
                                            <option value="BE">Belgium</option>
                                            <option value="BZ">Belize</option>
                                            <option value="BJ">Benin</option>
                                            <option value="BM">Bermuda</option>
                                            <option value="BT">Bhutan</option>
                                            <option value="BO">Bolivia, Plurinational State of</option>
                                            <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                            <option value="BA">Bosnia and Herzegovina</option>
                                            <option value="BW">Botswana</option>
                                            <option value="BV">Bouvet Island</option>
                                            <option value="BR">Brazil</option>
                                            <option value="IO">British Indian Ocean Territory</option>
                                            <option value="BN">Brunei Darussalam</option>
                                            <option value="BG">Bulgaria</option>
                                            <option value="BF">Burkina Faso</option>
                                            <option value="BI">Burundi</option>
                                            <option value="KH">Cambodia</option>
                                            <option value="CM">Cameroon</option>
                                            <option value="CA">Canada</option>
                                            <option value="CV">Cape Verde</option>
                                            <option value="KY">Cayman Islands</option>
                                            <option value="CF">Central African Republic</option>
                                            <option value="TD">Chad</option>
                                            <option value="CL">Chile</option>
                                            <option value="CN">China</option>
                                            <option value="CX">Christmas Island</option>
                                            <option value="CC">Cocos (Keeling) Islands</option>
                                            <option value="CO">Colombia</option>
                                            <option value="KM">Comoros</option>
                                            <option value="CG">Congo</option>
                                            <option value="CD">Congo, the Democratic Republic of the</option>
                                            <option value="CK">Cook Islands</option>
                                            <option value="CR">Costa Rica</option>
                                            <option value="CI">Côte d'Ivoire</option>
                                            <option value="HR">Croatia</option>
                                            <option value="CU">Cuba</option>
                                            <option value="CW">Curaçao</option>
                                            <option value="CY">Cyprus</option>
                                            <option value="CZ">Czech Republic</option>
                                            <option value="DK">Denmark</option>
                                            <option value="DJ">Djibouti</option>
                                            <option value="DM">Dominica</option>
                                            <option value="DO">Dominican Republic</option>
                                            <option value="EC">Ecuador</option>
                                            <option value="EG">Egypt</option>
                                            <option value="SV">El Salvador</option>
                                            <option value="GQ">Equatorial Guinea</option>
                                            <option value="ER">Eritrea</option>
                                            <option value="EE">Estonia</option>
                                            <option value="ET">Ethiopia</option>
                                            <option value="FK">Falkland Islands (Malvinas)</option>
                                            <option value="FO">Faroe Islands</option>
                                            <option value="FJ">Fiji</option>
                                            <option value="FI">Finland</option>
                                            <option value="FR">France</option>
                                            <option value="GF">French Guiana</option>
                                            <option value="PF">French Polynesia</option>
                                            <option value="TF">French Southern Territories</option>
                                            <option value="GA">Gabon</option>
                                            <option value="GM">Gambia</option>
                                            <option value="GE">Georgia</option>
                                            <option value="DE">Germany</option>
                                            <option value="GH">Ghana</option>
                                            <option value="GI">Gibraltar</option>
                                            <option value="GR">Greece</option>
                                            <option value="GL">Greenland</option>
                                            <option value="GD">Grenada</option>
                                            <option value="GP">Guadeloupe</option>
                                            <option value="GU">Guam</option>
                                            <option value="GT">Guatemala</option>
                                            <option value="GG">Guernsey</option>
                                            <option value="GN">Guinea</option>
                                            <option value="GW">Guinea-Bissau</option>
                                            <option value="GY">Guyana</option>
                                            <option value="HT">Haiti</option>
                                            <option value="HM">Heard Island and McDonald Islands</option>
                                            <option value="VA">Holy See (Vatican City State)</option>
                                            <option value="HN">Honduras</option>
                                            <option value="HK">Hong Kong</option>
                                            <option value="HU">Hungary</option>
                                            <option value="IS">Iceland</option>
                                            <option value="IN">India</option>
                                            <option value="ID">Indonesia</option>
                                            <option value="IE">Ireland</option>
                                            <option value="IM">Isle of Man</option>
                                            <option value="IL">Israel</option>
                                            <option value="IT">Italy</option>
                                            <option value="JM">Jamaica</option>
                                            <option value="JP">Japan</option>
                                            <option value="JE">Jersey</option>
                                            <option value="JO">Jordan</option>
                                            <option value="KZ">Kazakhstan</option>
                                            <option value="KE">Kenya</option>
                                            <option value="KI">Kiribati</option>
                                            <option value="XK">Kosovo</option>
                                            <option value="KR">Korea, Republic of</option>
                                            <option value="KW">Kuwait</option>
                                            <option value="KG">Kyrgyzstan</option>
                                            <option value="LA">Lao People's Democratic Republic</option>
                                            <option value="LV">Latvia</option>
                                            <option value="LB">Lebanon</option>
                                            <option value="LS">Lesotho</option>
                                            <option value="LR">Liberia</option>
                                            <option value="LY">Libya</option>
                                            <option value="LI">Liechtenstein</option>
                                            <option value="LT">Lithuania</option>
                                            <option value="LU">Luxembourg</option>
                                            <option value="MO">Macao</option>
                                            <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                            <option value="MG">Madagascar</option>
                                            <option value="MW">Malawi</option>
                                            <option value="MY">Malaysia</option>
                                            <option value="MV">Maldives</option>
                                            <option value="ML">Mali</option>
                                            <option value="MT">Malta</option>
                                            <option value="MH">Marshall Islands</option>
                                            <option value="MQ">Martinique</option>
                                            <option value="MR">Mauritania</option>
                                            <option value="MU">Mauritius</option>
                                            <option value="YT">Mayotte</option>
                                            <option value="MX">Mexico</option>
                                            <option value="FM">Micronesia, Federated States of</option>
                                            <option value="MD">Moldova, Republic of</option>
                                            <option value="MC">Monaco</option>
                                            <option value="MN">Mongolia</option>
                                            <option value="ME">Montenegro</option>
                                            <option value="MS">Montserrat</option>
                                            <option value="MA">Morocco</option>
                                            <option value="MZ">Mozambique</option>
                                            <option value="MM">Myanmar</option>
                                            <option value="NA">Namibia</option>
                                            <option value="NR">Nauru</option>
                                            <option value="NP">Nepal</option>
                                            <option value="NL">Netherlands</option>
                                            <option value="NC">New Caledonia</option>
                                            <option value="NZ">New Zealand</option>
                                            <option value="NI">Nicaragua</option>
                                            <option value="NE">Niger</option>
                                            <option value="NG">Nigeria</option>
                                            <option value="NU">Niue</option>
                                            <option value="NF">Norfolk Island</option>
                                            <option value="MP">Northern Mariana Islands</option>
                                            <option value="NO">Norway</option>
                                            <option value="OM">Oman</option>
                                            <option value="PK">Pakistan</option>
                                            <option value="PW">Palau</option>
                                            <option value="PS">Palestinian Territory, Occupied</option>
                                            <option value="PA">Panama</option>
                                            <option value="PG">Papua New Guinea</option>
                                            <option value="PY">Paraguay</option>
                                            <option value="PE">Peru</option>
                                            <option value="PH">Philippines</option>
                                            <option value="PN">Pitcairn</option>
                                            <option value="PL">Poland</option>
                                            <option value="PT">Portugal</option>
                                            <option value="PR">Puerto Rico</option>
                                            <option value="QA">Qatar</option>
                                            <option value="RE">Réunion</option>
                                            <option value="RO">Romania</option>
                                            <option value="RW">Rwanda</option>
                                            <option value="BL">Saint Barthélemy</option>
                                            <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                            <option value="KN">Saint Kitts and Nevis</option>
                                            <option value="LC">Saint Lucia</option>
                                            <option value="MF">Saint Martin (French part)</option>
                                            <option value="PM">Saint Pierre and Miquelon</option>
                                            <option value="VC">Saint Vincent and the Grenadines</option>
                                            <option value="WS">Samoa</option>
                                            <option value="SM">San Marino</option>
                                            <option value="ST">Sao Tome and Principe</option>
                                            <option value="SA">Saudi Arabia</option>
                                            <option value="SN">Senegal</option>
                                            <option value="RS">Serbia</option>
                                            <option value="SC">Seychelles</option>
                                            <option value="SL">Sierra Leone</option>
                                            <option value="SG">Singapore</option>
                                            <option value="SX">Sint Maarten (Dutch part)</option>
                                            <option value="SK">Slovakia</option>
                                            <option value="SI">Slovenia</option>
                                            <option value="SB">Solomon Islands</option>
                                            <option value="ZA">South Africa</option>
                                            <option value="GS">South Georgia and the South Sandwich Islands</option>
                                            <option value="SS">South Sudan</option>
                                            <option value="ES">Spain</option>
                                            <option value="LK">Sri Lanka</option>
                                            <option value="SD">Sudan</option>
                                            <option value="SR">Suriname</option>
                                            <option value="SJ">Svalbard and Jan Mayen</option>
                                            <option value="SZ">Swaziland</option>
                                            <option value="SE">Sweden</option>
                                            <option value="CH">Switzerland</option>
                                            <option value="TW">Taiwan, Province of China</option>
                                            <option value="TJ">Tajikistan</option>
                                            <option value="TZ">Tanzania, United Republic of</option>
                                            <option value="TH">Thailand</option>
                                            <option value="TL">Timor-Leste</option>
                                            <option value="TG">Togo</option>
                                            <option value="TK">Tokelau</option>
                                            <option value="TO">Tonga</option>
                                            <option value="TT">Trinidad and Tobago</option>
                                            <option value="TN">Tunisia</option>
                                            <option value="TR">Turkey</option>
                                            <option value="TM">Turkmenistan</option>
                                            <option value="TC">Turks and Caicos Islands</option>
                                            <option value="TV">Tuvalu</option>
                                            <option value="UG">Uganda</option>
                                            <option value="UA">Ukraine</option>
                                            <option value="AE">United Arab Emirates</option>
                                            <option value="GB">United Kingdom</option>
                                            <option value="US">United States</option>
                                            <option value="UM">United States Minor Outlying Islands</option>
                                            <option value="UY">Uruguay</option>
                                            <option value="UZ">Uzbekistan</option>
                                            <option value="VU">Vanuatu</option>
                                            <option value="VE">Venezuela, Bolivarian Republic of</option>
                                            <option value="VN">Viet Nam</option>
                                            <option value="VG">Virgin Islands, British</option>
                                            <option value="VI">Virgin Islands, U.S.</option>
                                            <option value="WF">Wallis and Futuna</option>
                                            <option value="EH">Western Sahara</option>
                                            <option value="ZM">Zambia</option>
                                            <option value="ZW">Zimbabwe</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                                    <div class="col-12 col-lg-5">
                                        <label for="city">{{ $t('checkout-city') }}</label>
                                        <input class="form-control border border-1 border-white rounded" placeholder="City" v-model="city" type="text" required id="city" >
                                    </div>
                                    <div class="col-12 col-lg-5">
                                        <label for="zip">{{ $t('checkout-zip') }}</label>
                                        <input class="form-control border border-1 border-white rounded" placeholder="Zip Code" v-model="zip" type="text" required id="zip" >
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                                    <div class="col-2">
                                        <label for="phone">{{ $t('Phone Prefix') }}</label>
                                        <select required name="phonePrefix" class="form-control text-white border border-1 border-white rounded" id="phonePrefix" v-model="phonePrefix">
                                            <option value="0" selected disabled></option>
                                            <option value="+358">+358</option>
                                            <option value="+355">+355</option>
                                            <option value="+213">+213</option>
                                            <option value="+1">+1</option>
                                            <option value="+376">+376</option>
                                            <option value="+244">+244</option>
                                            <option value="+672">+672</option>
                                            <option value="+54">+54</option>
                                            <option value="+374">+374</option>
                                            <option value="+297">+297</option>
                                            <option value="+61">+61</option>
                                            <option value="+43">+43</option>
                                            <option value="+994">+994</option>
                                            <option value="+973">+973</option>
                                            <option value="+880">+880</option>
                                            <option value="+375">+375</option>
                                            <option value="+32">+32</option>
                                            <option value="+501">+501</option>
                                            <option value="+229">+229</option>
                                            <option value="+975">+975</option>
                                            <option value="+591">+591</option>
                                            <option value="+599">+599</option>
                                            <option value="+387">+387</option>
                                            <option value="+267">+267</option>
                                            <option value="+55">+55</option>
                                            <option value="+246">+246</option>
                                            <option value="+673">+673</option>
                                            <option value="+359">+359</option>
                                            <option value="+226">+226</option>
                                            <option value="+257">+257</option>
                                            <option value="+855">+855</option>
                                            <option value="+237">+237</option>
                                            <option value="+238">+238</option>
                                            <option value="+236">+236</option>
                                            <option value="+235">+235</option>
                                            <option value="+56">+56</option>
                                            <option value="+86">+86</option>
                                            <option value="+61">+61</option>
                                            <option value="+61">+61</option>
                                            <option value="+57">+57</option>
                                            <option value="+269">+269</option>
                                            <option value="+242">+242</option>
                                            <option value="+243">+243</option>
                                            <option value="+682">+682</option>
                                            <option value="+506">+506</option>
                                            <option value="+225">+225</option>
                                            <option value="+385">+385</option>
                                            <option value="+53">+53</option>
                                            <option value="+599">+599</option>
                                            <option value="+357">+357</option>
                                            <option value="+420">+420</option>
                                            <option value="+45">+45</option>
                                            <option value="+253">+253</option>
                                            <option value="+593">+593</option>
                                            <option value="+20">+20</option>
                                            <option value="+503">+503</option>
                                            <option value="+240">+240</option>
                                            <option value="+291">+291</option>
                                            <option value="+372">+372</option>
                                            <option value="+251">+251</option>
                                            <option value="+500">+500</option>
                                            <option value="+298">+298</option>
                                            <option value="+679">+679</option>
                                            <option value="+358">+358</option>
                                            <option value="+33">+33</option>
                                            <option value="+594">+594</option>
                                            <option value="+689">+689</option>
                                            <option value="+262">+262</option>
                                            <option value="+241">+241</option>
                                            <option value="+220">+220</option>
                                            <option value="+995">+995</option>
                                            <option value="+49">+49</option>
                                            <option value="+233">+233</option>
                                            <option value="+350">+350</option>
                                            <option value="+30">+30</option>
                                            <option value="+299">+299</option>
                                            <option value="+590">+590</option>
                                            <option value="+502">+502</option>
                                            <option value="+44">+44</option>
                                            <option value="+224">+224</option>
                                            <option value="+245">+245</option>
                                            <option value="+592">+592</option>
                                            <option value="+509">+509</option>
                                            <option value="+672">+672</option>
                                            <option value="+39">+39</option>
                                            <option value="+504">+504</option>
                                            <option value="+852">+852</option>
                                            <option value="+36">+36</option>
                                            <option value="+354">+354</option>
                                            <option value="+91">+91</option>
                                            <option value="+62">+62</option>
                                            <option value="+353">+353</option>
                                            <option value="+44">+44</option>
                                            <option value="+972">+972</option>
                                            <option value="+39">+39</option>
                                            <option value="+81">+81</option>
                                            <option value="+44">+44</option>
                                            <option value="+962">+962</option>
                                            <option value="+7">+7</option>
                                            <option value="+254">+254</option>
                                            <option value="+686">+686</option>
                                            <option value="+383">+383</option>
                                            <option value="+82">+82</option>
                                            <option value="+965">+965</option>
                                            <option value="+996">+996</option>
                                            <option value="+856">+856</option>
                                            <option value="+371">+371</option>
                                            <option value="+961">+961</option>
                                            <option value="+266">+266</option>
                                            <option value="+231">+231</option>
                                            <option value="+218">+218</option>
                                            <option value="+423">+423</option>
                                            <option value="+370">+370</option>
                                            <option value="+352">+352</option>
                                            <option value="+853">+853</option>
                                            <option value="+389">+389</option>
                                            <option value="+261">+261</option>
                                            <option value="+265">+265</option>
                                            <option value="+60">+60</option>
                                            <option value="+960">+960</option>
                                            <option value="+223">+223</option>
                                            <option value="+356">+356</option>
                                            <option value="+692">+692</option>
                                            <option value="+596">+596</option>
                                            <option value="+222">+222</option>
                                            <option value="+230">+230</option>
                                            <option value="+262">+262</option>
                                            <option value="+52">+52</option>
                                            <option value="+691">+691</option>
                                            <option value="+373">+373</option>
                                            <option value="+377">+377</option>
                                            <option value="+976">+976</option>
                                            <option value="+382">+382</option>
                                            <option value="+212">+212</option>
                                            <option value="+258">+258</option>
                                            <option value="+95">+95</option>
                                            <option value="+264">+264</option>
                                            <option value="+674">+674</option>
                                            <option value="+977">+977</option>
                                            <option value="+31">+31</option>
                                            <option value="+687">+687</option>
                                            <option value="+64">+64</option>
                                            <option value="+505">+505</option>
                                            <option value="+227">+227</option>
                                            <option value="+234">+234</option>
                                            <option value="+683">+683</option>
                                            <option value="+672">+672</option>
                                            <option value="+47">+47</option>
                                            <option value="+968">+968</option>
                                            <option value="+92">+92</option>
                                            <option value="+680">+680</option>
                                            <option value="+970">+970</option>
                                            <option value="+507">+507</option>
                                            <option value="+675">+675</option>
                                            <option value="+595">+595</option>
                                            <option value="+51">+51</option>
                                            <option value="+63">+63</option>
                                            <option value="+48">+48</option>
                                            <option value="+351">+351</option>
                                            <option value="+974">+974</option>
                                            <option value="+262">+262</option>
                                            <option value="+40">+40</option>
                                            <option value="+7">+7</option>
                                            <option value="+250">+250</option>
                                            <option value="+590">+590</option>
                                            <option value="+290">+290</option>
                                            <option value="+590">+590</option>
                                            <option value="+508">+508</option>
                                            <option value="+685">+685</option>
                                            <option value="+378">+378</option>
                                            <option value="+239">+239</option>
                                            <option value="+966">+966</option>
                                            <option value="+221">+221</option>
                                            <option value="+381">+381</option>
                                            <option value="+248">+248</option>
                                            <option value="+232">+232</option>
                                            <option value="+65">+65</option>
                                            <option value="+421">+421</option>
                                            <option value="+386">+386</option>
                                            <option value="+677">+677</option>
                                            <option value="+252">+252</option>
                                            <option value="+27">+27</option>
                                            <option value="+82">+82</option>
                                            <option value="+211">+211</option>
                                            <option value="+34">+34</option>
                                            <option value="+94">+94</option>
                                            <option value="+249">+249</option>
                                            <option value="+597">+597</option>
                                            <option value="+47">+47</option>
                                            <option value="+268">+268</option>
                                            <option value="+46">+46</option>
                                            <option value="+41">+41</option>
                                            <option value="+963">+963</option>
                                            <option value="+886">+886</option>
                                            <option value="+992">+992</option>
                                            <option value="+255">+255</option>
                                            <option value="+66">+66</option>
                                            <option value="+670">+670</option>
                                            <option value="+228">+228</option>
                                            <option value="+690">+690</option>
                                            <option value="+676">+676</option>
                                            <option value="+216">+216</option>
                                            <option value="+90">+90</option>
                                            <option value="+993">+993</option>
                                            <option value="+688">+688</option>
                                            <option value="+256">+256</option>
                                            <option value="+380">+380</option>
                                            <option value="+971">+971</option>
                                            <option value="+44">+44</option>
                                            <option value="+598">+598</option>
                                            <option value="+998">+998</option>
                                            <option value="+678">+678</option>
                                            <option value="+379">+379</option>
                                            <option value="+58">+58</option>
                                            <option value="+84">+84</option>
                                            <option value="+681">+681</option>
                                            <option value="+212">+212</option>
                                            <option value="+967">+967</option>
                                            <option value="+260">+260</option>
                                            <option value="+263">+263</option>
                                        </select>

                                    </div>
                                    <div class="col-9">
                                        <label for="phone">{{ $t('Phone') }}</label>
                                        <input class="form-control border border-1 border-white rounded" placeholder="Phone" type="text" v-model="phone" required id="phone" >
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                                    <div class="col-12 ">
                                        <label for="email">{{ $t('Email') }}</label>
                                        <input class="form-control border border-1 border-white rounded" placeholder="Email" type="email" v-model="email" required id="email" @input="validateEmail()" >
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                                    <div class="col-12 " >
                                        <label >{{ $t('Password') }}</label>
                                        <div class="d-flex border border-1 border-white rounded pe-2 displayFlex">
                                            <input id="password" placeholder="Password" :type="passType?'password':'text'" class="form-control" v-model="password" required autocomplete="off" >
                                            <i class="icons-Eye-Visible my-auto" style="justify-self:  end; align-self: flex-end;" @click="changeTipePsw()" ></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-column flex-lg-row gap-5"  style="display:flex!important;">
                                    <div class="col-12 " >
                                        <label >{{ $t('conf-psw') }}</label>
                                        <div class="d-flex border border-1 border-white rounded pe-2 displayFlex">
                                            <input id="confirmPassword" placeholder="Confirm Password" :type="confType?'password':'text'" class="form-control" v-model="confirmPassword" required autocomplete="off"   @input="validatePsw">
                                            <i class="icons-Eye-Visible my-auto" style="justify-self:  end; align-self: flex-end;" @click="changeTipeConfPsw()" ></i>
                                        </div>
                                        <span v-if="pswError" class="error text-danger">{{ pswError }}</span>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12">
                                        <div class="d-flex align-items-center gap-2 displayFlex" :style="checkText!=''?'border-bottom:1px solid red;padding-bottom:5px':''">
                                            <input type="checkbox" class="form-check-input border border-2 border-light" id="customCheck1" v-model="isChecked">
                                            <label class="form-check-label" for="customCheck1">{{  $t('Agree') }}<a target="_blank" href="https://ofpfunding.com/terms-conditions/">{{ $t('T&C') }}</a></label>
                                        </div>
                                        <p class="text-danger text-uppercase" v-if="checkText!=''">{{ checkText }}</p>
                                    </div>
                                </div>
                                <div class="form-group text-center p-b-20">
                                    <div class="col-xs-12">
                                        <button class="btn btn-primary btn-lg w-100 rounded waves-effect waves-light"  type="submit" :disabled="!registerok || loadingRegister">
                                            <span v-if="!loadingRegister">{{ $t('SingUp') }}</span>
                                            <i v-else class="fa fa-spin fa-spinner"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group m-b-0">
                                    <div class="col-sm-12 text-center">
                                        {{  $t('AlreadyClients') }} <a :href="'/'+ langParam" class="text-primary m-l-5"><b class="text-primary fw-bold">{{ $t('SingIn') }}</b></a>
                                    </div>
                                </div>
                        </form>
                        </div>
                    </div>
                </div>
                <div class="col-5 d-none d-lg-flex loginDx position-relative">
                    <div class="position-absolute posizioneImg w-100 rounded">
                        <img class="img-fluid" src="/assets/images/imgLoginRegister.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>

    </section>

</body>
</template>
<script>
import toastMessage from '../components/statics/toastMessage.vue'
import Spinner from "../components/Spinner.vue";

export default {
    components: {
        toastMessage,
        Spinner
    },
    data() {
            return {
                registrationStatus:false,
                showToast:false,
                message:'',
                subMessage:'',
                registerok:false,
                confType:true,
                passType:true,
                isChecked:false,
                checkText:'',
                firstName: "",
                lastName: "",
                birthday: "",
                bio: "",
                address: "",
                city: "",
                zip: "",
                country: "",
                phone: "",
                email: "",
                emailError:'',
                password: "",
                confirmPassword:'',
                pswError:'',
                error: null,
                locale: 'en',
                langParam:'',
                loadingRegister: false,
                phonePrefix: '',
            }
        },
        methods: {
            checkUrlLang() {
                const supportedLangs = ['en', 'it', 'pt', 'es'];
                this.langParam = this.$route.params.lang || 'en';
                this.$i18n.locale = 'en';

                if (supportedLangs.includes(this.langParam)) {
                    this.$i18n.locale = this.langParam;
                }
            },
            goBuy(){
                window.location.replace('https://ofpfunding.com/account-types/')
            },
            changeTipePsw(){
                this.passType=!this.passType
            },
            changeTipeConfPsw(){
                this.confType=!this.confType
            },
            isValidForm() {
                return (this.firstName || '').length > 1
                    && (this.lastName || '').length > 1
                    && (this.birthday || '').length > 1
                    && (this.address || '').length > 1
                    && (this.city || '').length > 1
                    && (this.zip || '').length > 1
                    && (this.country || '').length > 1
                    && (this.phone || '').length > 1
                    && (this.email || '').length > 1
                    && (this.password || '').length > 1
                    && (this.confirmPassword || '').length > 1
                    && (this.phonePrefix || '').length > 1
                    && this.isChecked;
            },
            async createClient() {
                if (!this.isChecked) {
                    this.checkText = 'Required';
                    return;
                }

                if (!this.isValidForm()) {
                    this.message='Error'
                    this.subMessage='Please fill all the fields'
                    this.showToast=true
                    setTimeout(() => {
                        this.showToast = false;
                    }, 10000);
                    return;
                }

                this.origin = this.$route.query?.ref_origin
                    || this.$cookies.get('ref_origin')
                    || sessionStorage.getItem('ref_origin')
                    || localStorage.getItem('ref_origin')
                    // || this.$cookies.get('utm_campaign')
                    || null;

                let campaign = this.$route.query.utm_campaign
                    || this.$cookies.get('utm_campaign')
                    || null;
                let utmId = this.$route.query.utm_id
                    || this.$cookies.get('utm_id')
                    || null;
                let utmMedium = this.$route.query.utm_medium
                    || this.$cookies.get('utm_medium')
                    || null;
                let utmSource = this.$route.query.utm_source
                    || this.$cookies.get('utm_source')
                    || null;

                let numberPhone = this.phone.replace(/[^0-9]/g, '');
                numberPhone = this.phonePrefix + numberPhone;
                numberPhone = (parseInt(numberPhone)).toFixed(0)
                const payload = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password,
                    country: this.country ,
                    address: this.address,
                    city: this.city,
                    zip: this.zip,
                    phone: numberPhone,
                    birthday: this.birthday,
                    bio_description: this.bio,
                    ref_origin:this.origin,
                    marketing: {
                        campaign: campaign,
                        id: utmId,
                        medium: utmMedium,
                        source: utmSource
                    },
                    language: this.langParam || 'en',
                    referred_by: this.$cookies.get('ofp_aff') || null,
                    bannerId: this.$cookies.get('a_bid') || null
                };

                try {
                    this.loadingRegister = true;
                    const response = await axios.post(`${this.$baseUrl}/customers/createCustomer`, payload)
                    this.message='Thanks for Registering'
                    this.subMessage='Please verify your email Inbox'
                    this.registrationStatus=true
                    try {
                        window.sendinblue.track(
                            'registered_client',
                            {
                                email: this.email,
                                FIRSTNAME: this.firstName,
                                LASTNAME: this.lastName
                            },
                            {
                                data: {
                                    email: this.email,
                                    FIRSTNAME: this.firstName,
                                    LASTNAME: this.lastName
                                }
                            }
                        );
                    } catch (e) {
                        console.error('brevo', e);
                    }
                    try {
                        this.$gtm.trackEvent({
                            event: 'sign_up',
                            value: response.data
                        });
                    } catch (e) {
                        console.error('gtm', e)
                    }
                    this.showToast=true
                    setTimeout(() => {
                        this.showToast = false;
                        window.location.replace('/');
                    }, 10000);
                } catch(e) {
                    console.error(e);
                    this.message=e
                    this.subMessage='Please try to register again'
                    this.showToast=true
                    setTimeout(() => {
                        this.showToast = false;
                    }, 10000);
                    return;
                } finally {
                    this.loadingRegister = false;
                }
            },
            validateEmail() {
                // Regular expression to match the format of an email address
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                // Check if the input matches the regular expression
                if (!regex.test(this.email)) {
                this.emailError = 'Please enter a valid email address.';
                } else {
                this.emailError = '';
                }
             },
             validatePsw() {
                if (this.password !== this.confirmPassword) {
                    this.pswError = 'Password do not match';
                    this.registerok=false
                } else {
                    this.pswError = '';
                    this.registerok=true
             }},
        },
        watch: {
            locale(newLocale) {
                this.$i18n.locale = newLocale
            },
            password(){
            this.validatePsw()
        },
        confirmPassword(){
            this.validatePsw()
        }
        },
        mounted(){
            this.checkUrlLang()
        }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;500;700&display=swap');

.o-income .c3 line, .o-income .c3 path {
  stroke: #e9ecef; }

.news-slide .carousel-item {
  min-height: 515px;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.08) 56%, rgba(0, 0, 0, 0.65) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.08) 56%, rgba(0, 0, 0, 0.65) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.08) 56%, rgba(0, 0, 0, 0.65) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
  /* IE6-9 */ }

.news-slide .carousel-caption {
  text-align: left;
  left: 25px;
  right: 25px; }

#cc-table span a i {
  font-size: 25px;
 }

label{
    color:#ffffff!important;
}
.form-group{
    margin-bottom:20px!important;
}
.login-box{
    width: 100%;
}
input{
    background-color: transparent!important;
}
select{
    background-color: transparent!important;
}
select>option{
    background-color: #1e1f41;
}
.displayFlex{
    display: flex!important;
}
.form-group{
    margin-bottom:20px ;
}
input{
    background-color: transparent!important;
    padding: 10px!important;
}
.loginDx{
    background-color:rgba(255, 255, 255,0.1);
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding-bottom: 200px;
}
.posizioneImg{
    transform: translate(-15%,-15%);
}


input[type="date"] {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 4px;
    padding: 5px;
    height: auto;  /* Adattare secondo le proprie esigenze */
    width: 100%;  /* Adattare secondo le proprie esigenze */
}

input[type="date"]::-webkit-datetime-edit {
    display: block;
    padding: 0; /* Adattare secondo le proprie esigenze */
    /* Adattare secondo le proprie esigenze */
    border-color: transparent;
}

</style>

