<template>
    <footer class="footer">
        <p>FINTECHNOLOGY LTD London, The UK - Copyright © 2024</p>
        <span class="mb-1">GFP FUNDING Is A Trademark Brand Name Owned by FINTECHNOLOGY LTD UK (Company Number: 15131112). The Services Provided On This Website Are Professional And Achievement Services. The Outcome Of The Proposed Services Is Necessarily Determined By The Individual's Professional Skill Level And Ability To Perform Under The Program Guidelines And Objectives As Discussed For Each Service. Clients Are Advised To Conduct A Thorough Study Of The Requirements Of The Program Before Signing Up For Any Of The Services. Hypothetical Performance Results Have Many Inherent Limitations. Some Of Which Are Described Below. No Representation Is Being Made That Any Account Will Or Is Likely To Achieve Profits Or Losses Similar To Those Shown. In Fact, There Are Frequently Sharp Differences Between Hypothetical Performance Results And The Actual Results Subsequently Achieved By Any Particular Trading Program. One Of The Limitations Of Hypothetical Performance Results Is That They Are Generally Prepared With The Benefit Of Hindsight. In Addition, Hypothetical Trading Does Not Involve Financial Risk, And No Hypothetical Trading Record Can Completely Account For The Impact Of Financial Risk Of Actual Trading. For Example, The Ability To Withstand Losses Or To Adhere To A Particular Trading Program In Spite Of Trading Losses Are Material Points Which Can Also Adversely Affect Actual Trading Results. There Are Numerous Other Factors Related To The Markets In General Or To The Implementation Of Any Specific Trading Program Which Cannot Be Fully Accounted For In The Preparation Of Hypothetical Performance Results And All Which Can Adversely Affect Trading Results.</span>
        <span class="mb-1">Fintechnology and GFP Funding are not a broker and do not accept deposits.</span>
        <span>Payonetics provides payment processing services to prop trading Fintechnology solely for facilitating transactions during their evaluation period. This service is provided on a limited basis and does not constitute an ongoing commitment by Payonetics to process payments for any further stages.</span>
    </footer>
</template>

<script>
export default {
    name: "footerBase"
}
</script>

<style scoped lang="scss">
.footer {
    position: sticky;
    z-index: 999;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #0c0926;
    border: none;

    @media (max-width: 768px) {
        position: static;
    }
}
span{
    font-size: 6px;
}
</style>
