<template>
    <div id="main-wrapper">
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar" >
            <Navbar />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar" >
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar" >
                <!-- Sidebar navigation-->
                <Sidebar @rank="watchRank" />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->

        <div class="page-wrapper" :class="showRank?'opacity-25':''" >
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->

                <!--- Start Content --->
                <div class="row my-5">
                    <div class="col-12">
                        <EconomicCalendar/>
                    </div>
                </div>
                <!--- End Content --->

            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <RankModal :showRank="showRank" @close="closeRank"/>
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer />
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Navbar from "./statics/navbar";
import Footer from "./statics/footer";
import EconomicCalendar from "./EconomicCalendar";
import bannerKyc from "./statics/bannerKyc.vue";
import RankModal from "./RankModal";
export default {
    name: "News",
    components: { Footer, Navbar, Sidebar, EconomicCalendar,bannerKyc,RankModal },
    data(){
        return{
            showRank:false,
        }
    },
    mounted(){

    },
    beforeMount(){
        if(!this.$cookies.get('emailAccount') ){
            this.$router.push({ path: '/',})
        }
    },
    methods:{
        closeRank(){
            this.showRank=false
        },
        watchRank(value){
            console.log(value)
            this.showRank=value
        },
        }


}
</script>

<style scoped>

</style>
