import { defineStore } from "pinia";

export const useRedeemStore = defineStore("redeem", {
    state: () => ({
        email : null,
    }),
    getters: {

    },
    actions: {
       async verifyRedeem (code) {
            const response = await this.$httpClient.post("/redeems/verify", {
                code: code,
                email: this.email,
            });
            console.log(response.data['redeem'])
            return response;
       }
    },
});
