
import {createWebHistory, createRouter} from "vue-router";
import Home from '../pages/Home'
import Register from '../pages/Register';
import Login from '../pages/Login';
// import Posts from '../components/Posts';
// import EditPost from '../components/EditPost';
// import AddPost from '../components/AddPost';
import TradingRules from '../components/TradingRules'
import error404 from '../components/error404';
import error403 from '../components/error403';
import error500 from '../components/error500';
import error503 from '../components/error503';
import BuyAccount from '../pages/BuyAccount';
import LostPsw from '../pages/LostPsw';
import Orders from '../components/Orders';
import Calendar from '../pages/Calendar.vue';
import ResetPsw from '../pages/ResetPsw';
import UserSetting from '../components/UserSetting';
import ChartLineMobile from '../components/ChartLineMobile';
import News from '../components/News';
import Checkout from '../pages/Checkout.vue';
import LeaderBoard from '../components/LeaderBoard';
import FinaNews from '../components/FinaNews';
import AreaChartMobile from '../components/AreaChartMobile';
import VerifyEmail from '../pages/VerifyEmail';
import TradesChartDay from '../components/TradesChartDay';
import Oauth from '../pages/Oauth';
import Cons from '../components/ConsistencyChart'
import SuccPay from '../pages/SuccPay.vue';
import FailPay from '../components/FailPay';
import Spinner from '../components/Spinner';
import BundleBFShop from '../components/BundleBFShop'
import CheckoutBundle from '../components/CheckoutBundle';
import TestGtm from '../pages/TestGtm';
import Payouts from '../components/Payouts';
import CertificatePDF from '../components/CertificatePDF';
import Bundles from "../pages/Bundles.vue";
import Certificati from '../components/Certificati';
import Profile from "../pages/Profile.vue";
import BuyCustomizedAccount from '../pages/BuyCustomizedAccount';
import Giveaway from '../pages/Giveaway';

export const routes = [
    {
        name:'BundleBFShop',
        path:'/_black-friday',
        component:BundleBFShop
    },
    {
        name: 'home',
        path: '/home',
        component: Home
    },
    {
        name: 'News',
        path: '/news',
        component: FinaNews
    },
    {
        name: 'Payouts',
        path: '/payouts',
        component: Payouts
    },
    {
        name: 'Certificati',
        path: '/certificate',
        component: Certificati
    },
    {
        name: 'economicCalendar',
        path: '/economic-calendar',
        component: News
    },
    {
        name: 'mobileLine',
        path: '/mobileLine',
        component: ChartLineMobile
    },
    {
        name: 'chartareamobile',
        path: '/chartareamobile',
        component: AreaChartMobile
    },
    {
        name: 'register',
        path: '/register/:lang?',
        component: Register
    },

    {
        name: 'login',
        path: '/:lang?',
        component: Login,
        beforeEnter: (to, from, next) => {
            // affiliazione
            const affiliateParam = to.query.affiliate;
            if (affiliateParam) {
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 7);
                const affiliateData = {
                    value: affiliateParam,
                    expires: expirationDate.getTime()
                };
                localStorage.setItem('ofpaffref', JSON.stringify(affiliateData));
            } else {
                localStorage.removeItem('ofpaffref');
            }
            next();
        },
    },
    {
        name: 'reset',
        path: '/reset/:lang?',
        component: LostPsw,

    },
    {
        name: 'reset-password',
        path: '/password-reset/:token',
        component: ResetPsw
    },
    {
        name: 'oauth',
        path: '/oauth/:token',
        component: Oauth
    },
    {
        name: 'verify-email',
        path: '/verify-email/:token',
        component: VerifyEmail
    },
    {
        name: 'rules',
        path: '/rules',
        component: TradingRules
    },
    {
        name: 'checkout',
        path: '/checkout',
        component: Checkout
    },
    {
        name: 'checkoutBundle',
        path: '/checkout/bundle',
        component: CheckoutBundle
    },
       {
          name: 'leaderboard',
          path: '/leaderboard',
          component: LeaderBoard
      },
    {
        name: '404',
        path: '/404',
        component: error404
    },
    {
        name:'TradesChart',
        path:'/tradeschart',
        component: TradesChartDay
    },
    {
        name: '403',
        path: '/403',
        component: error403
    },
    {
        name: '500',
        path: '/500',
        component: error500
    },
    {
        name: '503',
        path: '/503',
        component: error503
    },
    {
        path: '/profile/:accountId',
        name: 'profile',
        component: Profile
    },
    {
        path: '/log-out',
        name: 'logout',
        beforeEnter: (to, from, next) => {
            // Rimuovere tutti i cookie
            const cookies = Object.keys(this.$cookies.getAll());
            cookies.forEach(cookie => {
                this.$cookies.remove(cookie);
            });

            // Esegui il reindirizzamento alla radice '/'
            next('/');
        },
    },
    {
        name: 'orders',
        path: '/orders',
        component: Orders
    },
    {
        name: 'calendar',
        path: '/calendar',
        component: Calendar
    },
    {
        name: 'user',
        path: '/user',
        component: UserSetting
    },
     {
         name: 'acc',
         path: '/buy-account',
         component: BuyCustomizedAccount
     },
     {
         name: 'successPay',
        path: '/successPayment',
         component: SuccPay
     },
     {
         name: 'failPay',
        path: '/failPayment',
         component: FailPay
     },
    {
        name: 'cons',
        path: '/cons',
        component: Cons,
    },
    {
        name:'Spinner',
        path:'/Spin',
        component:Spinner
    },
    {
        name: 'test-gtm',
        path: '/test-gtm',
        component: TestGtm
    },
    {
        name:'sharePDF',
        path:'/certificate/:hash',
        component: CertificatePDF
    },
    {
        name: 'bundles',
        path: '/bundles',
        component: Bundles
    },
    {
        name: 'giveaway',
        path: '/giveaway',
        component: Giveaway
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

export default router;
