<template>
    <div id="main-wrapper">
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar">
            <Navbar />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar" >
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar">
                <!-- Sidebar navigation-->
                <Sidebar />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper d-flex align-items-center" style="height:100vh;">
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->

                <!--- Start Content --->
                <div class="row">
                    <div class="col-12">
                        <div  class="d-flex flex-column align-items-center justify-content-center" style="display: flex!important;">
                            <div class="text-success fw-bold mb-2 d-flex justify-content-center" style="font-size: 300px;">
                                <img src="../../../public/images/green.gif" alt="Green GIF" style="width: 30%;" />
                            </div>
                            <h2 class="text-white">{{ $t('succ-pay') }}</h2>
                            <RouterLink class=" btn btn-primary mt-2 " to="/orders">{{ $t('succ-paybtn') }}</RouterLink>
                        </div>
                    </div>
                </div>
                <!--- End Content --->

            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer />
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>

<script setup>
import {getCurrentInstance, onMounted} from 'vue';
import axios from 'axios';

// Library
import {RouterLink, useRouter} from "vue-router";
import {useCookies} from "vue3-cookies";

// Components
import Sidebar from "../components/Sidebar.vue";
import Navbar from "../components/statics/navbar.vue";
import Footer from "../components/statics/footer.vue";
import {useGtm} from "@gtm-support/vue-gtm";
import { useUserStore } from '../store/user.store';
import { storeToRefs } from 'pinia';

// Library
const $router = useRouter();
const { cookies } = useCookies();
const $gtm = useGtm();

// Misc
const baseUrl = getCurrentInstance().appContext.config.globalProperties.$baseUrl;

// Stores
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

// Hooks
onMounted(async () => {
    window.opener?.postMessage('success', '*');

    const customerId = $router.currentRoute.value.query.customer_id
        || cookies.get('client')?._id
        || cookies.get('emailAccount')
        || user.value?._id;
    if (customerId) {
        console.debug('customerId', customerId);

        const paymentType = $router.currentRoute.value.query.payment_type || '';

        try {
            const {data} = await axios.get(`${baseUrl}/customers/orders/${customerId}`);
            if ((data || []).length > 0) {
                const lastOrders = data[0];
                const itemId = lastOrders.items[0].itemId;

                const item = await axios.get(`${baseUrl}/ecommerce/getItem?id=${itemId}`);
                if (item.data) {
                    $gtm.trackEvent({
                        event: 'purchase',
                        value: {
                            ORDERID: lastOrders._id,
                            ITEM: item.data.itemDetails.id,
                            PRICE: item.data.itemDetails.price || 0,
                            CURR: item.data.itemDetails.currency,
                            SIZE: new Intl.NumberFormat('en-US').format(item.data.itemDetails.size),
                            NAME: item.data.itemDetails.name,

                            ecommerce: {
                                purchase: {
                                    actionField: {
                                        id: lastOrders._id,
                                        affiliation: 'OFP',
                                        revenue: item.data.itemDetails.price || 0,
                                        tax: 0,
                                        shipping: 0,
                                        coupon: '',
                                        currency: item.data.itemDetails.currency,
                                    },
                                    products: [{
                                        name: item.data.itemDetails.name,
                                        id: item.data.itemDetails.id,
                                        price: item.data.itemDetails.price || 0,
                                        currency: item.data.itemDetails.currency,
                                        brand: '',
                                        category: '',
                                        variant: '',
                                        quantity: 1,
                                        coupon: ''
                                    }]
                                }
                            }
                        },
                    })

                    $gtm.push({
                        event: 'purchase_v2',
                        ecommerce: {
                            value: item.data.itemDetails.price || 0,
                            currency: item.data.itemDetails.currency,
                            transaction_id: lastOrders.id,
                            coupon: lastOrders.coupon,
                            payment_type: paymentType,
                            items: [{
                                id: item.data.itemDetails.id,
                                name: item.data.itemDetails.name,
                                price: item.data.itemDetails.price || 0,
                                quantity: 1
                            }]
                        },
                        user_data: user.value
                            ? {
                                first_name: user.value.firstName,
                                last_name: user.value.lastName,
                                email_address: user.value.email,
                                phone_number: user.value.phone,
                                user_id: user.value._id,
                                country: user.value.country,
                                city: user.value.city,
                                state: user.value.state,
                                postal_code: user.value.zip
                                    ? user.value.zip.replaceAll("-", "").replaceAll(" ", "")
                                    : undefined,
                            }
                            : {},
                    })
                }
            }
        } catch (e) {
            console.error(e);
        }
    }
});
</script>

<style scoped>

</style>
