import {defineStore} from "pinia";
import axios from "axios";

export const useItemsStore = defineStore('items', {
    state: () => ({
        items: [],
        bundles : [],
        cachedItem: {},
    }),
    getters: {
        getItemPropByProfitAmount: state => (profitAmount, prop) => {
            return state.items.find(item => item.name.startsWith(profitAmount))?.props[prop] || null;
        },
        getVersion: state => (profitAmount, size, currency_account) => {
            const version = state.items.find(item => item.name.startsWith(profitAmount))?.versions
                .find(version => +version.size === +size && version.currency_account.toLowerCase() === currency_account.toLowerCase());
            if (!version) {
                const bundle = state.bundles.find(item => item.name.startsWith(profitAmount))?.versions
                    .find(version => +version.size === +size && version.currency_account.toLowerCase() === currency_account.toLowerCase());
                if (bundle) {
                    return bundle;
                }

                return null;
            }

            return version;
        },
        getVersionPrice: state => (profitAmount, size, currency_account) => {
            const version = state.getVersion(profitAmount, size, currency_account);
            if (!version) {
                return null;
            }

            const { original_price, price, discount_value, discount_type, currency } = version;
            return { original_price, price, discount_value, discount_type, currency };
        },

        getVersionById: state => id => {
            const size = +id.slice(0, id.indexOf('K')) * 1000;
            const currency_account = id.slice(id.indexOf('K') + 1, id.indexOf('K') + 4);
            const profitAmount = +id.slice(id.indexOf('K') + 4);
            return state.getVersion(profitAmount, size, currency_account);
        },

        queryVersion: state => (id, query = {max_daily_drawdown:null})  => {
            if ( !query || !query.max_daily_drawdown) {
                return getVersionById(id)
            } else {
                const size = +id.slice(0, id.indexOf('K')) * 1000;
                const currency_account = id.slice(id.indexOf('K') + 1, id.indexOf('K') + 4);
                const profitAmount = +id.slice(id.indexOf('K') + 4);
                const item = state.items.find(
                    item => item.name.startsWith(profitAmount) &&
                    item.props && item.props.max_daily_drawdown === query.max_daily_drawdown)
                return item && item.versions ? item.versions.find(
                    version => +version.size === +size &&
                    version.currency_account.toLowerCase() === currency_account.toLowerCase()) : null
            }
        },
        queryBundle: state => async (_id, query = {payout_type, max_daily_drawdown:null}) => {
            if ( !query || !query.max_daily_drawdown) {
                return getVersionById(id)
            } else {
                let size = +_id.slice(0, _id.indexOf('K')) * 1000;
                let profitAmount = +_id.slice(_id.indexOf('K') + 4);
                let item = state.bundles.find(bundle => {
                    return bundle.items.find(item => item.item.endsWith(profitAmount) 
                        && item.max_daily_drawdown == query.max_daily_drawdown
                        && item.type === 'bundle' && item.size === size)
                })

                if (!item) {
                    return null;
                }

                const id = item.items[0].item;

                size = +id.slice(0, id.indexOf('K')) * 1000;
                const currency_account = id.slice(id.indexOf('K') + 1, id.indexOf('K') + 4);
                profitAmount = +id.slice(id.indexOf('K') + 4);
                const items = await state.fetchItemEx({ payout_type: query.payout_type, type: 'bundle' });
                item = items.find(
                    item => item.name.startsWith(profitAmount) && item.type === 'bundle'
                    && item.props && item.props.max_daily_drawdown === query.max_daily_drawdown 
                )

                const version = item.versions.find(
                    version => +version.size === +size &&
                    version.currency_account.toLowerCase() === currency_account.toLowerCase() && version.size === size)

                return version;
            }
        },
        getItemByVersionId: state => (versionId, payout_type, type, max_daily_drawdown) => {
            return state.items.find(item => {
                return item.props.max_daily_drawdown == max_daily_drawdown && item.versions.find(version => version.id === versionId)
                    && item.payout_type === payout_type
                    && item.type === type
            });
        },
    },
    actions: {
        async bundles() {
            const {data} = await this.$httpClient.get('/ecommerce/bundles');
            return data;
        },
        async fetchBundles({ payout_type }) {
            let url = `ecommerce/bundles`
            if (payout_type) {
                url += `?payout_type=${payout_type}`;
            }
            const {data} = await this.$httpClient.get(url);
            this.bundles = data;
            return data;
        },

        async fetchItems({ payout_type, type }) {
            let url = `/ecommerce/items`;
            if (payout_type) {
                url += `?payout_type=${payout_type}`;
            }
            if (type) {
                url += url.includes('?') ? `&type=${type}` : `?type=${type}`;
            }
            url += '&promo-rules=true';
            const {data} = await this.$httpClient.get(url);
            this.items = data;
            return data;
        },
        async getItemById(itemId, payout_type, type, max_daily_drawdown = 5) {
            const key = `${payout_type}-${type}-${itemId}-${max_daily_drawdown}`;
            if (this.cachedItem[key]) {
                return this.cachedItem[key];
            }

            const { data } = await this.$httpClient.get(`/ecommerce/items/${itemId}?type=${type}&payout_type=${payout_type}&max_daily_drawdown=${max_daily_drawdown}`);
            this.cachedItem[key] = data;
            return data;
        },
        async fetchItemEx({ payout_type, type }) {
            let url = `/ecommerce/items`;
            if (payout_type) {
                url += `?payout_type=${payout_type}`;
            }
            if (type) {
                url += url.includes('?') ? `&type=${type}` : `?type=${type}`;
            }
            url += '&promo-rules=true';
            const {data} = await this.$httpClient.get(url);
            return data;
        },
        async getBundleByVersionID (versionId, payout_type, max_daily_drawdown) {
            const items = await this.fetchItemEx({ payout_type, type: 'bundle' });

            const item = items.find(item => {
                return item.props.max_daily_drawdown == max_daily_drawdown && item.versions.find(version => version.id === versionId)
                    && item.payout_type === payout_type
                    && item.type === 'bundle'
            });

            if (!item) {
                return null;
            }

            const bundle = this.bundles.find(bundle => {
                return bundle.items.find(version => version.item === versionId && version.max_daily_drawdown == max_daily_drawdown
                    && version.payout_type === payout_type
                    && version.type === 'bundle'
                );
            });

            return {
                props: item.props,
                name: item.name,
                payout_type: item.payout_type,
                ...bundle,
            }
        }
    }
})
