import { defineStore } from "pinia";
import { useUserStore } from "./user.store";
import { useCookies } from "vue3-cookies";

export const useCartStore = defineStore("cart", {
    state: () => ({
        cart: null,
        goingThroughCheckout: false,
    }),
    getters: {
        getCart: (state) => {
            return state.cart;
        },
    },
    actions: {
        async addItemToCart(
            versionId,
            platform,
            tag,
            type,
            payout_type,
            max_daily_drawdown,
            email,
            note = ""
        ) {
            const { user, guestId } = useUserStore();

            const body = {
                item: versionId,
                platform,
                tag,
                type,
                payout_type,
                note,
            };
            if (max_daily_drawdown) {
                body.max_daily_drawdown = max_daily_drawdown;
            }
            
            body.customer_id = user?._id;

            const { data } = await this.$httpClient.post(
                `/ecommerce/cart/addItem`,
                body
            );

            return data;
        },
        async fetchCart() {
            const { data, headers } = await this.$httpClient.get(
                `/ecommerce/cart/getCart`
            );
            this.cart = data;

            const { cookies } = useCookies();
            if (headers['x-guest-id'] && !cookies.get("guest_id")) {
                useUserStore().setGuestId(headers['x-guest-id']);
                cookies.set("guest_id", headers['x-guest-id']);
            }
        },
        async deleteCart() {
            const { user } = useUserStore();

            await this.$httpClient.post(`/ecommerce/cleanCart`, {
                customer_id: user._id,
            });
            this.cart = null;
        },
        async checkCoupon(code) {
            const { user } = useUserStore();

            const { data } = await this.$httpClient.get(
                `/ecommerce/check-coupon?&code=${code}`
            );
            return data.data;
        },
        async addCoupon(code) {
            const { user } = useUserStore();
            await this.$httpClient.post(`/ecommerce/cart/addCouponToCart`, {
                coupon: code,
            });
        },
        async setPlatformForCart(customer_id, platform) {
            const payload = { customer_id, platform };
            await this.$httpClient.post(
                `/ecommerce/cart/setPlatformForCart`,
                payload
            );
        },
        async confirmOrder(order) {
            console.debug("Order:", order);
            return await this.$httpClient.post(
                `/ecommerce/cart/confirmOFPOrder`,
                order,
                { retry: 3, retryDelay: 3000 }
            );
        },
        payOrder(payload) {
            return this.$httpClient.post(`/ecommerce/order/pay`, payload);
        },
        clean() {
            const { user } = useUserStore();

            const payload = {
                customer_id: user?._id,
            };

            if (this.cart.guest_id) {
                payload.guest_id = this.cart.guest_id;
                delete payload.customer_id;
            }
            return this.$httpClient.post(`/ecommerce/cleanCart`, payload);
        },
    },
});
