export default {
    install: (app, options) => {
        app.config.globalProperties.$trackBrevoEvent = (event, customer, data) => {
            if (location.hostname.includes('dev') || location.hostname === '127.0.0.1' || location.hostname === 'localhost' || location.hostname.endsWith('.test')) {
                return;
            }

            return window.sendinblue.track(event, customer, { data, ...data });
        };

        app.provide('$trackBrevoEvent', app.config.globalProperties.$trackBrevoEvent);
    }
}
