export default {
    install: (app, options) => {
        const localeByIso = {
            'en': 'en-US',
            'es': 'es-ES',
            'it': 'it-IT',
            'pt': 'pt-PT',
        }
        const currentLocale = localeByIso['en'];

        app.config.globalProperties.$formatAmount = (amount, currency = 'USD', locale = null) => {
            return new Intl.NumberFormat(locale || currentLocale, { style: 'currency', currency })
                .format(amount);
        };
        app.config.globalProperties.$formatAmountRounded = (amount, currency = 'USD', locale = null) => {
            return new Intl.NumberFormat(locale || currentLocale, { style: 'currency', currency })
                .format(amount, {maximumFractionDigits: 0});
        };
        app.config.globalProperties.$formatNumber = (number, locale = null) => {
            return new Intl.NumberFormat(locale || currentLocale).format(number, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
            })
        }

        app.provide('$formatAmount', app.config.globalProperties.$formatAmount);
        app.provide('$formatAmountRounded', app.config.globalProperties.$formatAmountRounded);
        app.provide('$formatNumber', app.config.globalProperties.$formatNumber);
    }
}
