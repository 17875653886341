<template>
    <div id="main-wrapper">
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <header class="topbar" style="background-image: url(assets/images/sfondo.jpg);">
            <Navbar />
        </header>
        <!-- ============================================================== -->
        <!-- End Topbar header -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar" style="background-image: url(assets/images/sfondo.jpg);background-size: cover;background-attachment: fixed">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar" style="background-color: rgba(255, 255, 255, 0);">
                <!-- Sidebar navigation-->
                <Sidebar />
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper d-flex align-items-center" style="height:100vh;background-image: url(assets/images/sfondo.jpg);background-size: cover;background-attachment: fixed">
            <!-- ============================================================== -->
            <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="container-fluid">
                <!-- ============================================================== -->
                <!-- Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!-- End Bread crumb and right sidebar toggle -->
                <!-- ============================================================== -->
                <!--- Start Content --->
                <div class="row" >
                    <div class="col-12 d-flex flex-column justify-content-center" style="display: flex!important">
                        <div  class="d-flex flex-column align-items-center justify-content-center">
                            <div class="text-danger fw-bold mb-2" style="font-size: 300px;">
                                <img src="../../../public/images/red.gif" alt="Red GIF" />
                            </div>
                            <h2 class="text-white">{{ $t('PayFail') }}</h2>
                            <h6>{{ $t('PayFail-text') }}</h6>
                            <a class=" btn btn-primary mt-2 " href="/orders">{{ $t('orders-title') }}</a>
                            </div>
                        </div>
                    </div>
                <!--- End Content --->

            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <Footer />
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Navbar from "./statics/navbar";
import Footer from "./statics/footer";
export default {
    name: "News",
    components: { Footer, Navbar, Sidebar },
    data(){
        return{

        }
    },
    mounted(){

    },
    methods:{

        }


}
</script>

<style scoped>

</style>

