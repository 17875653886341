<template>
  <div id="main-wrapper">
    <header class="topbar" :class="{ 'opacity-25': loading }" >
      <Navbar />
    </header>

    <aside class="left-sidebar" :class="{ 'opacity-25': loading }">
      <div class="scroll-sidebar">
        <Sidebar
          :prevent-navigation="!user?._id"
        />
      </div>
    </aside>
    <div class="bg page-wrapper  position-relative">
      <Spinner v-if="loading" />
      <div class="header">
        <h1>
          Get a <span>FREE</span> Instantly Funded Account with our Buy One Get One OFFER 
          <img src="/assets/icons/info.svg" alt="info" class="heading__info" @click="showInfoPopup" />
        </h1>
        <p class="step">STEP 1</p>
        <p class="text">Choose between the Total allocation</p>
        <div class="values">
          <p @click="scrollTo(bundles[0].note)" role="button">
            $ 50K
          </p>
          or
          <p @click="scrollTo(bundles[1].note)" role="button">
            $ 100K
          </p>
        </div>
        <p class="step">STEP 2</p>
        <p class="text">
          Get ready to get EXTRA<br/>
          Trading Capital without Any EXTRA COST!
        </p>
        <small>
          Instantly Funded Accounts without any challenges or verification phase to pass.
        </small>
        <div class="limited-offer">
          <img src="/assets/images/star.svg" />
          <div class="limited-offer__title">
            LIMITED TIME OFFER
          </div>
          <div class="countdown">
            <span id="days">{{ daysDiff }}</span>
            <p>
              days
            </p>
          </div>
          <div class="countdown">
            <span id="hours">{{ hoursDiff }}</span>
            <p>
              hours
            </p>
          </div>
          <div class="countdown">
            <span id="minutes">{{ minDiff }}</span>
            <p>
              minutes
            </p>
          </div>
        </div>
      </div>

      <div id="bundlesCarousel" class="bundles-mobile carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div v-for="(bundle, index) in bundles" :key="index" class="bundle-mobile-item carousel-item" :class="{'active': index === 0}">
            <div class="bundle-mobile-item__header">
              Total Allocation
              <span>
                {{ bundle.totalAllocation }} K
              </span>
            </div>
            <table class="bundle-mobile-item__table">
              <thead>
                <tr>
                  <th>Offer #{{ index + 1 }}</th>
                  <th>Buy this</th>
                  <th>Get this FREE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Account Size</td>
                  <td class="primary">{{ bundle.accounts[0].size }} K</td>
                  <td class="primary">{{ bundle.accounts[1].size }} K</td>
                </tr>
                <tr>
                  <td>Currency</td>
                  <td>{{ bundle.accounts[0].currency }}</td>
                  <td>{{ bundle.accounts[1].currency }}</td>
                </tr>
                <tr>
                  <td>Payout Model</td>
                  <td class="text-capitalize">{{ bundle.accounts[0].payout }}</td>
                  <td class="text-capitalize">{{ bundle.accounts[1].payout }}</td>
                </tr>
                <tr>
                  <td>Profit Split</td>
                  <td>{{ bundle.accounts[0].split }}%</td>
                  <td>{{ bundle.accounts[1].split }}%</td>
                </tr>
                <tr>
                  <td>Daily Drawdown</td>
                  <td>{{ bundle.accounts[0].drawdown }}%</td>
                  <td>{{ bundle.accounts[1].drawdown }}%</td>
                </tr>
              </tbody>
            </table>
            <div class="prices">
              <div class="prices__text">
                <p>
                  Only
                </p>
                <h3>
                  ${{ bundle.price }}
                </h3>
              </div>
              <button class="btn" @click="saveOnChookies(bundle.note)">
                Buy now
              </button>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" role="button">
          <img src="/assets/icons/arrow.svg" alt="arrow" class="arrow-img" />
        </a>
        <a class="carousel-control-next" role="button">
          <img src="/assets/icons/arrow.svg" alt="arrow" class="arrow-img right" />
        </a>
      </div>

      <div class="bundles p-md-4">
        <div v-for="(bundle, index) in bundles" :key="index" class="bundle-item">
          <h2>
            {{ bundle.title }}
          </h2>
          <p class="subtitle">
            Two <span>Instantly Funded</span> Accounts
          </p>
          <div class="total-allocation">
            <p>Total Allocation</p>
            <span>
              {{ bundle.totalAllocation }} K
            </span>
          </div>
          <template v-for="(account, index) in bundle.accounts" :key="index">
            <ul class="account-table">
              <li class="account-title" v-if="index === 0">Buy Account One</li>
              <li class="account-title second" v-else>Get Account Two - <span>For FREE</span></li>
              <li>
                <p>
                  Account Size
                </p>
                <span class="primary">{{ account.size }}K</span>
              </li>
              <li>
                <p>
                  Currency
                </p>
                <span>{{ account.currency }}</span>
              </li>
              <li>
                <p>
                  Payout Model
                </p>
                <span class="text-capitalize">{{ account.payout }}</span>
              </li>
              <li>
                <p>
                  Profit Split
                </p>
                <span>{{ account.split }}%</span>
              </li>
              <li>
                <p>
                  Daily Drawdown
                </p>
                <span>{{ account.drawdown }}%</span>
              </li>
            </ul>
            <p v-if="index < bundle.accounts.length - 1" class="plus">+</p>
          </template>
          <div class="prices">
            <p>
              Only
            </p>
            <h3>
              ${{ bundle.price }}
            </h3>
            <button class="btn" @click="saveOnChookies(bundle.note)"  :id="'bundle-' + bundle.note">
              Buy now
            </button>
          </div>
        </div>
      </div>
    </div>
    <footerBundle :prevent-navigation="!user?._id" />
  </div>
</template>
<script setup>
import { ref, onMounted, defineComponent, compile, render, h } from 'vue';
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";

import Navbar from "../components/statics/navbar";
import Spinner from "../components/Spinner";
import Sidebar from "../components/Sidebar";
import { useUserStore } from '../store/user.store';
import { storeToRefs } from 'pinia';
import { useItemsStore } from '../store/items.store';
import footerBundle from '../components/statics/footerBundle.vue';
import { usePopup } from '../store/popup.store';

const router = useRouter();

const { cookies } = useCookies();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const itemsStore = useItemsStore();
const { bundles: getBundles } = itemsStore;
const { popup } = usePopup();

const loading = ref(false);
const bundles = ref([
  {
    id: "25KUSD80",
    title: 'Bogo Offer #1',
    totalAllocation: 50,
    accounts: [
      {
        size: 25,
        currency: 'USD',
        payout: 'On-Demand',
        split: 80,
        drawdown: 5,
        type: "custom"
      },
      {
        size: 25,
        currency: 'USD',
        payout: 'Monthly',
        split: 80,
        drawdown: 3,
        type: "bundle"
      }
    ],
    price: 399,
    note: "BUNDLE1-SEPT2024"
  },
  {
    id: "50KUSD80",
    title: 'Bogo Offer #2',
    totalAllocation: 100,
    accounts: [
      {
        size: 50,
        currency: 'USD',
        payout: 'On-Demand',
        split: 80,
        drawdown: 5,
        type: "custom"
      },
      {
        size: 50,
        currency: 'USD',
        payout: 'Monthly',
        split: 80,
        drawdown: 3,
        type: "bundle"
      }
    ],
    price: 788,
    note: "BUNDLE2-SEPT2024",
  }
]);
const untilDate = new Date('2024-10-14 23:59:59');
const daysDiff = ref('00');
const minDiff = ref('00');
const hoursDiff = ref('00');
const boundled = ref(false);

onMounted(async () => {
  // if time passed, redirect to buy-account page
  if (new Date() > untilDate) {
    router.replace('/buy-account');
  }

  getTimeDiff();
  setInterval(getTimeDiff, 60000);

  if (!boundled) {
  const _bundles = await getBundles();
    bundles.value = _bundles.map(bundle => {
      const itemName = bundle.items[0].item;
      const totalAllocation = itemName.substring(0, itemName.indexOf('K'));

      return {
        id: bundle.id,
        title: bundle.name,
        totalAllocation,
        accounts: bundle.items.map(item => {
          const currency = item.item.substring(item.item.indexOf('K') + 1, item.item.indexOf('K') + 4);
          const split = item.item.substring(item.item.length - 2)

          return {
            size: item.size / 1000,
            currency,
            payout: item.payout_type,
            split,
            drawdown: item.max_daily_drawdown,
            type: item.type
          }
        }),
        note: bundle.id,
        price: bundle.price
      };
    });
  }
  boundled.value = true;

  setTimeout(() => {
    $('#bundlesCarousel .carousel-control-prev').click(() => {
      document.querySelector('.carousel-inner').scrollTo({
        left: 0,
        behavior: 'smooth'
      });
    });
    $('#bundlesCarousel .carousel-control-next').click(() => {
      const $last = $('#bundlesCarousel .carousel-item').last();
      document.querySelector('.carousel-inner').scrollTo({
        left: $last.offset().left,
        behavior: 'smooth'
      });
    });
  }, 1000);
});

const getTimeDiff = () => {
  const currentDate = new Date();
  const timeLeft = untilDate.getTime() - currentDate.getTime();
  if (timeLeft <= 0) {
    router.replace('/buy-account');
  }

  let delta = Math.abs(timeLeft) / 1000;

  const days = Math.floor(delta / 86400).toString();
  daysDiff.value = days.length === 1 ? `0${days}` : days;
  delta -= Number(days) * 86400;
  const hours = (Math.floor(delta / 3600) % 24).toString();
  hoursDiff.value = hours.length === 1 ? `0${hours}` : hours;
  delta -= Number(hours) * 3600;
  const min = (Math.floor(delta / 60) % 60).toString();
  minDiff.value = min.length === 1 ? `0${min}` : min;
}

const saveOnChookies = (note) => {
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    cookies.set('bundle', note, { expires: expirationDate });
    router.push('/checkout');
}

const scrollTo = note => {
  const el = document.getElementById(`bundle-${note}`);
  window.scrollTo({
    top: el.offsetTop - 100,
    behavior: 'smooth'
  });

  el.classList.add('pulse');
  setTimeout(() => {
    el.classList.remove('pulse');
  }, 2000);
}

const popupContent = ({
  template: `
    <p class="step">STEP 1</p>
    <p class="text">Choose between the Total allocation</p>
    <div class="values">
      <p role="button">$ 50K</p>
      or
      <p role="button">$ 100K</p>
    </div>
    <p class="step">STEP 2</p>
    <p class="text">
      Get ready to get EXTRA<br/>
      Trading Capital without Any EXTRA COST!
    </p>

    <style>
    .values p{background:linear-gradient(180.46deg,rgba(110,124,255,.03) .4%,rgba(23,19,83,.3) 150.44%);font-size:38px;font-weight:500;line-height:55.81px;color:#ff6844;padding:5px 24px;border-radius:8px;box-shadow:0 4px 4px 0 rgba(92,88,88,.25) inset;position:relative;margin-bottom:0;width:100%;z-index:998}@media (min-width:768px){.values{flex-direction:row;display:flex}.values p{width:unset}}.values p::before{content:'';position:absolute;top:0;left:0;right:0;bottom:0;border-radius:8px;padding:2px;background:linear-gradient(180deg,rgba(102,102,102,.6) 0,rgba(212,212,212,0) 100%),linear-gradient(248.52deg,rgba(151,171,255,.6) 13.21%,rgba(173,173,173,.3) 44.76%,#97abff 65.26%,#24208f 72.35%);-webkit-mask:linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);mask:linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);mask-composite:exclude;-webkit-mask-composite:destination-out}
    </style>
  `,
});
const showInfoPopup = () => {
  popup({
    body: popupContent.template,
  })
}

</script>
<style scoped lang="scss">
  .bg {
    padding: 60px 16px;

    @media (min-width: 768px) {
      padding: 140px 100px;
    }
  }

  .header {
    font-family: Heebo;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      font-size: 28px;
      font-weight: 500;
      line-height: 41.13px;
      color: rgba(255, 255, 255, 0.93);
      margin-bottom: 30px;
      margin-top: 30px;

      span {
        color: rgba(254, 53, 17, 0.93);
      }

      @media (min-width: 768px) {
        line-height: 52.88px;
        font-size: 36px;
        margin-top: 0px;
      }
    }

    .step {
      color: rgba(151, 171, 255, 1);
      font-size: 14px;
      font-weight: 400;
      line-height: 20.56px;
      margin-bottom: 8px;
      display: none;

      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 26.44px;
        display: block;
      }
    }

    .text {
      font-size: 16px;
      font-weight: 500;
      line-height: 29.38px;
      color: rgba(255, 255, 255, 0.87);
      display: none;

      @media (min-width: 768px) {
        font-size: 20px;
        display: block;
      }
    }

    small {
      font-size: 14px;
      font-weight: 300;
      line-height: 20.56px;
      color: rgba(255, 255, 255, 0.87);
      display: none;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 23.5px;
        display: block;
      }
    }

    .values {
      display: none;
      justify-content: space-evenly;
      font-size: 20px;
      font-weight: 500;
      line-height: 29.38px;
      gap: 12px;
      margin-bottom: 28px;
      align-items: center;
      text-transform: uppercase;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
        display: flex;
      }

      p {
        background: linear-gradient(180.46deg, rgba(110, 124, 255, 0.03) 0.4%, rgba(23, 19, 83, 0.3) 150.44%);
        font-size: 38px;
        font-weight: 500;
        line-height: 55.81px;
        color: rgba(255, 104, 68, 1);
        padding: 5px 24px;
        border-radius: 8px;
        box-shadow: 0px 4px 4px 0px rgba(92, 88, 88, 0.25) inset;
        position: relative;
        margin-bottom: 0px;
        width: 100%;
        z-index:998;

        @media (min-width: 768px) {
          width: unset;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 8px;
          padding: 2px;
          background: linear-gradient(180deg, rgba(102, 102, 102, 0.6) 0%, rgba(212, 212, 212, 0) 100%),
            linear-gradient(248.52deg, rgba(151, 171, 255, 0.6) 13.21%, rgba(173, 173, 173, 0.3) 44.76%, #97ABFF 65.26%, #24208F 72.35%);
          -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          mask-composite: exclude;
          -webkit-mask-composite: destination-out;
        }
      }
    }

    .limited-offer {
      background: linear-gradient(180deg, rgba(31, 25, 124, 0.6) -116.3%, #100D3F 100%);
      backdrop-filter: blur(30px);
      border-radius: 36px;
      display: flex;
      padding: 40px 16px;
      margin-top: 26px;
      position: relative;
      margin-bottom: 40px;
      width: 100%;

      @media (min-width: 768px) {
        margin-top: 60px;
        padding: 45px 96px;
        margin-bottom: calc(60px - 24px);
        width: unset;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 36px;
        padding: 2px;
        background: linear-gradient(248.52deg, rgba(151, 171, 255, 0.6) 13.21%, rgba(173, 173, 173, 0.3) 44.76%, #97ABFF 65.26%, #24208F 72.35%);
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask-composite: exclude;
        -webkit-mask-composite: destination-out;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(0.6) translate(-80%, -80%);
        z-index: 2;

        @media (min-width: 768px) {
          transform: translate(-47%, -47%);
        }
      }

      &__title {
        background: radial-gradient(50% 50% at 50% 50%, #231F90 0%, #161254 100%);
        border-radius: 12px;
        position: absolute;
        font-size: 16px;
        font-weight: 600;
        line-height: 23.5px;
        padding: 12px 32px;
        color: rgba(217, 217, 217, 1);
        top: 0;
        transform: translateY(-26px);
        left: 0;
        right: 0;
        width: fit-content;
        margin: 0 auto;

        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 29.38px;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 12px;
          padding: 2px;
          background: linear-gradient(357.92deg, rgba(58, 58, 58, 0) -100.94%, rgba(215, 215, 215, 0.4) 124.8%);
          -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
          mask-composite: exclude;
          -webkit-mask-composite: destination-out;
        }
      }

      .countdown {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 16px;
        padding: 8px;
        width: 116px;
        margin-right: 12px;

        @media (min-width: 768px) {
          margin-right: 44px;
          padding: 15px;
        }

        &:last-of-type {
          margin-right: 0;
        }

        span {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 900;
          line-height: 48px;
          color: rgba(255, 255, 255, 1);

          @media (min-width: 768px) {
            font-size: 32px;
          }
        }

        p {
          color: rgba(165, 222, 255, 1);
          font-family: Poppins;
          font-size: 11.77px;
          font-weight: 300;
          line-height: 17.66px;
          text-transform: uppercase;
          margin-bottom: 0;
          margin-top: 8px;

          @media (min-width: 768px) {
            margin-top: 12px;
          }
        }
      }
    }
  }

  .bundles {
    font-family: Heebo;
    display: none;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    gap: 60px;
    color: rgba(255, 255, 255, 0.8);

    @media (min-width: 768px) {
      flex-direction: row;
      display: flex;
      gap: 100px;
    }

    .bundle-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: linear-gradient(180deg, #462446 0%, #0D043F 7.67%, #0A0D16 67.5%);
      padding: 32px 28px;
      border-radius: 16px;
      width: 100%;


      @media (min-width: 768px) {
        width: unset;
        height: fit-content;
      }

      h2 {
        font-size: 28px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        margin-bottom: 16px;
      }

      .subtitle {
        font-size: 18px;
        font-weight: 300;
        line-height: 28px;
        text-align: center;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 16px;
        margin-bottom: 20px;
        padding: 0 10px;
        span {
          font-weight: 700;
          color: rgba(255, 104, 68, 1);
        }
      }

      .total-allocation {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        p {
          margin-bottom: 0;
        }

        span {
          font-size: 48px;
          font-weight: 600;
          line-height: 40px;
          text-align: left;
          color: rgba(255, 104, 68, 1);
        }
      }

      .plus {
        color: rgba(255, 255, 255, 1);
        font-weight: 500;
        font-size: 36px;
        margin: 0;
        text-align: center;
      }

      .account-table {
        border: 1px solid rgba(251, 132, 105, 0.5);
        padding: 0;
        gap: 16px;
        border-radius: 8px;
        list-style: none;
        margin-bottom: 0;


        .account-title {
          background: rgba(255, 255, 255, 0.15);
          font-family: Heebo;
          font-size: 14px;
          font-weight: 500;
          line-height: 28px;
          text-align: center;
          padding: 11px;

          &.second {
            background: linear-gradient(246.65deg, rgba(255, 104, 68, 0.6) -2.03%, rgba(255, 104, 68, 0.6) 0.04%, #161254 67.14%);

            span {
              font-size: 18px;
              font-weight: 700;
              line-height: 26.44px;
            }
          }
        }

        li:not(.account-title) {
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          text-align: center;
          padding: 0 24px;
          margin: 5px 0;
          display: flex;
          justify-content: space-between;
          color: rgba(255, 255, 255, 0.6);

          p {
            margin-bottom: 0;

            &:before {
              content:"• ";
              color: rgba(255, 104, 68, 1);
            }
          }

          span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);

            &.primary {
              color: rgba(255, 104, 68, 1);
            }
          }
        }
      }

      .prices {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        p {
          font-size: 24px !important;
          font-weight: 400;
          line-height: 28px;
          text-align: center;
          color: rgba(255, 255, 255, 0.6);
        }

        h3 {
          font-size: 34px !important;
          font-weight: 700;
          line-height: 28px;
          text-align: center;
          color: rgba(255, 255, 255, 1);
        }

        .btn {
          margin-top: 16px;
        }
      }
    }
  }

  .btn {
    padding: 10px 8px 10px 8px;
    border-radius: 8px;
    background: rgba(255, 104, 70, 1);
    font-size: 16px;
    font-weight: 600;
    line-height: 23.5px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);
  }

  .bundles-mobile {
    display: inline-flex;
    gap: 16px;
    margin-bottom: 60px;
    width: 100%;
    z-index: 19;

    @media (min-width: 768px) {
      display: none;
    }

    .bundle-mobile-item {
      background: linear-gradient(180.46deg, rgba(60, 70, 158, 0.03) 0.4%, rgba(23, 19, 83, 0.3) 150.44%);
      border-radius: 8px;
      position: relative;
      width: 300px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 8px;
        padding: 2px;
        background: linear-gradient(9.31deg, rgba(151, 171, 255, 0.1) 0.23%, rgba(173, 173, 173, 0.3) 10.7%, #97ABFF 18.49%, rgba(38, 35, 102, 0.6) 25.33%),
                    linear-gradient(14.64deg, rgba(38, 35, 102, 0.6) 38.37%, rgba(173, 173, 173, 0.2) 48.93%, rgba(10, 8, 46, 0.6) 62.2%);
        -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        mask-composite: exclude;
        -webkit-mask-composite: destination-out;
        z-index: 1;
      }

      &__header {
        background: rgba(255, 104, 68, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        border-radius: 8px 8px 0 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 23.5px;
        color: rgba(255, 255, 255, 0.93);
        gap: 4px;

        span {
          font-size: 24px;
          font-weight: 600;
          line-height: 35.25px;
        }
      }

      &__table {
        color: rgba(255, 255, 255, 0.93);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        width: 100%;

        th {
          padding: 17.5px 10px;

          &:nth-child(2) {
            background: rgba(255, 255, 255, 0.03);
            text-align: center;
          }

          &:nth-child(3) {
            background: rgba(255, 255, 255, 0.07);
            text-align: center;
          }
        }

        tbody tr {
          td {
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
            color: rgba(255, 255, 255, 0.93);
            padding: 17.5px 8px;

            &.primary {
              color: rgba(255, 104, 68, 1);
            }

            &:first-of-type {
              font-size: 14px;
              font-weight: 400;
              line-height: 28px;
              color: rgba(255, 255, 255, 0.6);
            }

            &:nth-child(2) {
              background: rgba(255, 255, 255, 0.03);
              text-align: center;
            }

            &:nth-child(3) {
              background: rgba(255, 255, 255, 0.07);
              text-align: center;
            }
          }
        }
      }

      .prices {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 27px 40px;
        z-index: 998;
        position: relative;

        &__text {
          display: flex;
          justify-content: center;
          gap: 16px;

          p {
            font-size: 22px;
            font-weight: 400;
            line-height: 28px;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
          }

          h3 {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
            font-weight: bold;
            color: rgba(255, 255, 255, 0.93);
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
      display: none;
  }

  .carousel-inner {
      display: flex;
      overflow-x: scroll;

    }

    .carousel-item {
      flex: 0 0 90%; /* Main slide takes 70% of the width */
      margin-right: 2.5%; /* Space between slides */
      display: flex;
      flex-direction: column;
    }

.arrow-img {
  width: 40px;
  height: 40px;

  margin: 24px;

  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;

  &.right {
    transform: rotate(180deg);
  }
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}

.pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}
@keyframes pulse {
  from { transform: scale(1); }
  to { transform: scale(1.4); }
}

.heading__info {
  width: 22px;
  height: 22px;
  cursor: pointer;
  display: none;

  @media (max-width: 768px) {
    display: inline-block;
  }
}
</style>
