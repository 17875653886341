

/**
 * Check if item props field is valid 
 * (has all necessary properties)
 * @param {*} props 
 * @returns 
 */
export const isValidItemProps = (props) =>
    !!props &&
    !!props.max_daily_drawdown &&
    !!props.profit_split

/**
 * Check if item is valid 
 * (active and has all necessary properties)
 * @param item Item
 * @returns 
 */
export const isValidItem = (item) =>
    !!item &&
    !!item.versions &&
    !!item.active &&
    !!item.payout_type &&
    isValidItemProps(item.props) &&
    !!item.versions.find(isValidVersion) // at least 1 valid version

/**
 * Check if item version is valid 
 * (has all necessary properties and active)
 * @param {*} version 
 * @returns 
 */
export const isValidVersion = (version) =>
    !!version && 
    !!version.size && 
    !!version.active && 
    version.price > 0 && 
    !!version.currency_account

/**
 * Item array to capitals reducer
 * @param items valid Items 
 * @returns ordered array of sizes (capitals)
 */
export const items2Sizes = (items) => Array.from(
    items
        .reduce((values, item) =>
            item.versions
                .filter(isValidVersion)
                .reduce((values, version) => values.add(version.size), values), new Set())
).sort((a, b) => a - b)

/**
 * Item array to max daily drawdowns reducer
 * @param {*} items valid items
 * @returns ordered array of max daili drawdowns
 */
export const items2MaxDailyDrawdowns = (items) => Array.from(
    items
        .reduce((values, item) => values.add(item.props.max_daily_drawdown), new Set())
).sort((a, b) => a - b)

/**
 * Item array to profit splits reducer
 * @param {*} items valid items
 * @returns ordered array of profit splits
 */
export const items2ProfitSplits = (items) => Array.from(
    items
        .reduce((values, item) => values.add(item.props.profit_split), new Set())
).sort((a, b) => a - b)

/**
 * Item array to payout frequencies reducer
 * @param {*} items 
 * @returns payout frequencies (not ordered)
 */
export const items2PayoutFrequencies = (items) => Array.from(
    items
        .reduce((values, item) => values.add(item.payout_type), new Set())
)
/**
 * Item array to currencies reducer
 * @param {*} items 
 * @returns currencies (not ordered)
 */

export const items2Currencies = (items) => Array.from(
    items
        .reduce((values, item) =>
            item.versions
                .filter(isValidVersion)
                .reduce((values, version) => values.add(version.currency_account), values), new Set())
)