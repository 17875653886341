<template>
  <div class="payment-methods">
    <div class="payment-method__container">
      <img src="/assets/images/checkout/payment-methods/google.svg" />
    </div>
    <div class="payment-method__container">
      <img src="/assets/images/checkout/payment-methods/visa.svg" />
    </div>
    <div class="payment-method__container">
      <img src="/assets/images/checkout/payment-methods/mastercard.svg" />
    </div>
    <div class="payment-method__container">
      <img src="/assets/images/checkout/payment-methods/apple.svg" />
    </div>
  </div>
</template>
<style scoped lang="scss">
  .payment-methods {
    display: flex;
    gap: 6px;
  }

  :global(.payment-method__container) {
    align-items: center;
    border-radius: 4px;
    border: 1px solid #FFFFFF4D;
    display: flex;
    height: 28px;
    justify-content: center;
    padding: 0 9px;

    @media (max-width: 1000px) {
      & {
        align-self: flex-start;
        margin-top: 12px;
      }
    }
  }
</style>